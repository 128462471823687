import {COMMENT, content, CREATED_AT} from '../constants/stringsAndFields';
import {chunks, isNotEmpty} from '../utils/arrays';
import {BOOK_TABLE, reCreateBookTableAndData} from './book';
import {CHAPTER_TABLE, reCreateChapterTableAndData} from './chapter';
import {executeTransaction} from './common';
import {createFavoriteQuoteTable} from './favoriteQuote';
import {FOOTNOTE_TABLE, reCreateFootnoteTableAndData} from './footnote';
import {
  checkIntroductionSententesTable,
  createIntroductionSentencesTable,
  INTRODUCTION_SENTENCES_TABLE,
  reCreateIntroductionSentencesTableAndData,
} from './introductionSentences';
import {createNoteTable} from './note';
import {
  READING_PLAN_TABLE,
  reCreateReadingPlanTableAndData,
} from './readingPlan';
import {
  READING_PLAN_PROGRESS_TABLE,
  reCreateReadingPlanProgressTableAndData,
} from './readingPlanProgress';
import {VERSE_TABLE, reCreateVerseTableAndData} from './verse';
import {insertVersion} from './versions';

export const initTablesAndData = async (db, dbContent) => {
  await reCreateBookTableAndData(db, dbContent[BOOK_TABLE]);
  //const books = await getBooks(db);
  //console.log(books);
  await reCreateChapterTableAndData(db, dbContent[CHAPTER_TABLE]);
  //const chapters = await getChapters(db);
  //console.log(chapters);
  await reCreateFootnoteTableAndData(db, dbContent[FOOTNOTE_TABLE]);
  //const footnotes = await getFootnotes(db);
  //console.log(footnotes);
  await reCreateVerseTableAndData(db, dbContent[VERSE_TABLE]);
  //const verses = await getVerses(db);
  //console.log(verses);
  await createNoteTable(db);
  await createFavoriteQuoteTable(db);
  await reCreateReadingPlanTableAndData(db, dbContent[READING_PLAN_TABLE]);
  await reCreateReadingPlanProgressTableAndData(
    db,
    dbContent[READING_PLAN_PROGRESS_TABLE],
  );
  await createIntroductionSentencesTable(db);
  if (dbContent?.[INTRODUCTION_SENTENCES_TABLE]) {
    await reCreateIntroductionSentencesTableAndData(
      db,
      dbContent[INTRODUCTION_SENTENCES_TABLE],
    );
  }
  //const readingPlanProgresses = await getReadingPlanProgresses(db);
  //console.log(readingPlanProgresses);
  await insertVersion(db, {
    id: 2,
    [COMMENT]: 'init data loaded',
    [CREATED_AT]: null,
  });
};

export const initTimestampData = async (db, dbContent, migrationNumber) => {
  console.log(`Starting migration ${migrationNumber} of verse timestamps data`);
  await executeTransaction(db, dbContent[content]);
  await insertVersion(db, {
    id: migrationNumber,
    [COMMENT]: `init ${migrationNumber} verse timestamps data`,
    [CREATED_AT]: null,
  });
  console.log(
    `Migration ${migrationNumber} applied successfully (timestamps data)`,
  );
};

export const initReadingPlanData = async (db, dbContent) => {
  await executeTransaction(db, dbContent[content]);
  await insertVersion(db, {
    id: 7,
    [COMMENT]: 'additional reading plan',
    [CREATED_AT]: null,
  });
  console.log(
    'Migration 7 applied successfully (additional reading plan data)',
  );
};

export const initIntroChaptersData = async (db, dbContent) => {
  try {
    const tableExist = await checkIntroductionSententesTable(db);
    if (!tableExist) {
      await createIntroductionSentencesTable(db);
    }
    await executeTransaction(db, dbContent[content]);
    await insertVersion(db, {
      id: 8,
      [COMMENT]: 'init intro chapters data',
      [CREATED_AT]: null,
    });
    console.log('Migration 8 applied successfully (intro chapters data)');
  } catch (e) {
    console.log(e);
  }
};

export const parseTextContent = text => {
  try {
    if (text) {
      const lines = text.split(/\n/);
      if (lines) {
        const out = {};
        const bookLines = lines.filter(line => line.includes('`Book`'));
        const bookLinesChunks = chunks(bookLines, 30);
        out[BOOK_TABLE] = bookLinesChunks;
        // large data
        const chapterLines = lines.filter(line => line.includes('`Chapter`'));
        const chapterLinesChunks = chunks(chapterLines, 100);
        out[CHAPTER_TABLE] = chapterLinesChunks;
        const footnotesLines = lines.filter(line =>
          line.includes('`Footnote`'),
        );
        const footnotesLinesChunks = chunks(footnotesLines, 100);
        out[FOOTNOTE_TABLE] = footnotesLinesChunks;
        const versesLines = lines.filter(line => line.includes('`Verse`'));
        const versesLinesChunks = chunks(versesLines, 100);
        out[VERSE_TABLE] = versesLinesChunks;
        // Reading plans
        const readingPlanLines = lines.filter(line =>
          line.includes(`'ReadingPlan'`),
        );
        const readingPlanChunks = chunks(readingPlanLines, 10);
        out[READING_PLAN_TABLE] = readingPlanChunks;
        const readingPlanProgressLines = lines.filter(line =>
          line.includes(`'ReadingPlanProgress'`),
        );
        const readingPlanProgressLinesChunks = chunks(
          readingPlanProgressLines,
          100,
        );
        out[READING_PLAN_PROGRESS_TABLE] = readingPlanProgressLinesChunks;

        const introSentencesLines = lines.filter(line =>
          line.includes(`IntroductionSentences`),
        );
        if (isNotEmpty(introSentencesLines)) {
          const introSentencesLinesChunks = chunks(introSentencesLines, 10);
          out[INTRODUCTION_SENTENCES_TABLE] = introSentencesLinesChunks;
        }
        return out;
      }
    }
  } catch (e) {
    console.log(e);
  }
  return null;
};

export const parseMigrationTextContent = text => {
  try {
    if (text) {
      const lines = text.split(/\n/);
      if (lines) {
        return {[content]: chunks(lines, 10000)};
      }
    }
  } catch (e) {
    console.log(e);
  }
  return null;
};