import {StyleSheet, View} from 'react-native';
import {useTheme} from '../../theme/ThemeContext';

export const UIScreenContainer = ({children}) => {
  const {theme} = useTheme();
  const styles = getStyles(theme);

  return <View style={styles.container}>{children}</View>;
};

const getStyles = theme => {
  return StyleSheet.create({
    container: {
      flex: 1,
      width: '100%',
      height: '100%',
      maxWidth: theme.containerWidth,
      alignSelf: 'center',
      paddingHorizontal: theme.hMargin,
      paddingVertical: theme.vMargin,
    },
  });
};
