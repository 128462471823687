import {ScrollView} from 'react-native';
import {useTheme} from '../../theme/ThemeContext';

export const UIScreenScrollWrapper = ({children, scrollCallback = null}) => {
  const {theme} = useTheme();

  const scrollListener = e => {
    if (scrollCallback) {
      scrollCallback(e);
    }
  };

  return (
    <ScrollView
      contentContainerStyle={{
        flexGrow: 1,
        backgroundColor: theme.colors.background,
      }}
      scrollEventThrottle={16}
      onScroll={scrollListener}>
      {children}
    </ScrollView>
  );
};
