import {
  additionalBook,
  content,
  isIntroBook,
  positionInBook,
  sentenceId,
  verse_number,
} from '../constants/stringsAndFields';

export const jumpToVerseScript = (verse, chapter) =>
  isCurrentChapter(verse, chapter)
    ? `document.addEventListener("DOMContentLoaded", () => {
    const verseElement = document.querySelector(
      '#V${verse[verse_number]}'
    );
    setTimeout(function(){ 
      verseElement && verseElement.scrollIntoView();
  }, 300);
  });`
    : '';

export const isCurrentChapter = (verse, chapter) =>
  verse && chapter?.[positionInBook] === verse['chapter'] ? true : false;

export const getWebViewMenuItems = (
  chapter = null,
  customHtmlContent = null,
) =>
  customHtmlContent ||
  chapter?.[isIntroBook] === 1 ||
  chapter?.[additionalBook] === 1
    ? [
        {label: 'Kopiraj', key: 'copy'},
        {label: 'Podijeli', key: 'share'},
      ]
    : [
        {label: 'Omiljeni', key: 'favorite'},
        {label: 'Bilješka', key: 'note'},
        {label: 'Kopiraj', key: 'copy'},
        {label: 'Podijeli', key: 'share'},
      ];

export const areChapterWebViewPropsEqual = (oldProps, newProps) =>
  oldProps[content] === newProps[content] ? true : false;


export const getVerseElementId = verseData => {
  const elId = verseData.hasOwnProperty(verse_number)
    ? 'V' + verseData[verse_number]
    : verseData[sentenceId];
  return elId;
};
