import {getHeaderTitle} from '@react-navigation/elements';
import {useFocusEffect, useIsFocused} from '@react-navigation/native';
import {useCallback, useContext, useEffect, useState} from 'react';
import {View} from 'react-native';
import AudioPlayer from '../components/audio-player/AudioPlayer';
import {usePlayer} from '../components/audio-player/PlayerContext';
import ReadingView from '../components/reading/ReadingView';
import Icon from '../components/ui-components/Icon';
import UINavHeader from '../components/ui-components/UINavHeader';
import {UIPressable} from '../components/ui-components/UIPressable';
import UIScreenReaderWrapper from '../components/ui-components/UIScreenReadingWrapper';
import {
  audioChapterId,
  chapterData,
  chapterId,
  content,
  end_time,
  position,
  readingCompleted,
  readingContent,
  readingEntryId,
  readingPlanId,
} from '../constants/stringsAndFields';
import DataContext from '../context/dataContext';
import {useTheme} from '../theme/ThemeContext';
import {generateChapterHtml} from '../utils/html/html';
import {IS_MOBILE, IS_WEB} from '../utils/platform';
import {resolveReadingContent} from '../utils/readingPlanUtil';

const ReadingPlanDetailsScreen = ({navigation, route}) => {
  const {theme, fontStyle, fontSize} = useTheme();
  const {readingId = null} = route.params ?? {};
  const [isCompleted, setIsCompleted] = useState(false);
  const {
    getBookByName,
    getChapterByBookTitleAndChapter,
    setReadingCompletedAndSave,
    getVersesByChapter,
    getReadingPlanProgress,
  } = useContext(DataContext);
  const [reading, setReading] = useState(null);
  const [readingPlanData, setReadingPlanData] = useState(null);
  const [showSoundIcon, setShowSoundIcon] = useState(true);
  const [hideSoundForThisReadingPlan, setHideSoundForThisReadingPlan] =
    useState(false);
  const [audioModalVisible, setAudioModalVisible] = useState(false);
  const [readingVerse, setReadingVerse] = useState(null);
  const [verses, setVerses] = useState([]);
  const isFocused = useIsFocused();
  const {
    isPlayerOpen,
    openPlayer,
    trackAudioPosition,
    audioPosition,
    setForceCloseAudioPlayer,
    setPlayPlayerChapter,
    closePlayer,
  } = usePlayer();

  useFocusEffect(
    useCallback(() => {
      return () => {
        setPlayPlayerChapter(null);
        setReadingPlanData(null);
        closePlayer();
      };
    }, []),
  );

  const handleOpenPlayer = () => {
    if (IS_WEB) {
      setAudioModalVisible(!audioModalVisible);
    } else {
      openPlayer();
    }
  };

  const onSoundIconPress = () => {
    if (isPlayerOpen) {
      setForceCloseAudioPlayer(true);
    } else {
      openPlayer();
    }
  };

  useEffect(() => {
    if (readingId) {
      getReadingPlanProgress(readingId).then(readingVal => {
        setReading(readingVal);
      });
    }
  }, [readingId]);

  // Disable audio for reading plan 1 (without full book references)
  useEffect(() => {
    if (reading?.[readingPlanId] === 1) {
      setHideSoundForThisReadingPlan(true);
    }
  }, [reading]);

  // When navigated by audioPosition
  useEffect(() => {
    if (
      isFocused &&
      trackAudioPosition &&
      audioPosition?.[chapterId] &&
      audioPosition?.[position] &&
      verses
    ) {
      const matchedVerse = verses.find(
        v => v[end_time] >= audioPosition[position],
      );
      if (matchedVerse) {
        matchedVerse[audioChapterId] = audioPosition[chapterId];
        setReadingVerse(matchedVerse);
      }
    }
  }, [trackAudioPosition, audioPosition, verses, isFocused]);

  useEffect(() => {
    if (reading) {
      const fetchReadingData = async () => {
        let resolvedContent = null;
        try {
          resolvedContent = await resolveReadingContent(
            reading[readingContent],
            getBookByName,
            getChapterByBookTitleAndChapter,
          );
        } catch (e) {
          console.log(e);
        }

        if (resolvedContent) {
          navigation.setOptions({
            title: `${reading[readingContent]}`,
          });
          const chapter = resolvedContent['chapter'];
          getVersesByChapter(chapter).then(v => {
            setVerses(v);
          });
          setPlayPlayerChapter(chapter?.[chapterId]);
          setReadingPlanData({
            [content]: generateChapterHtml(
              resolvedContent,
              true,
              fontStyle,
              fontSize,
              theme,
            ),
            [chapterData]: chapter,
          });
        }
      };
      fetchReadingData().catch(console.error);
      setIsCompleted(reading[readingCompleted]);
    }
  }, [reading]);

  const onProgressClick = async _ => {
    const newCompleted = !isCompleted;
    setIsCompleted(newCompleted);
    await setReadingCompletedAndSave(reading, newCompleted);
    navigation.pop();
  };

  useEffect(() => {
    navigation.setOptions({
      header: ({navigation, route, options}) => {
        const title = getHeaderTitle(options, route.name);

        const headerLeft = (
          <UIPressable
            onPress={() => navigation.goBack()}
            style={{padding: theme.hMargin}}>
            <Icon name="arrow-left" color={theme.colors.text} size={18} />
          </UIPressable>
        );

        const headerRight = (
          <View style={{flexDirection: 'row'}}>
            {!hideSoundForThisReadingPlan && showSoundIcon && IS_MOBILE && (
              <UIPressable onPress={onSoundIconPress}>
                <Icon
                  name={isPlayerOpen ? 'volume-off' : 'volume-1'}
                  color={
                    isPlayerOpen ? theme.colors.tabIcon : theme.colors.primary
                  }
                  size={20}
                  style={{padding: theme.hMargin, paddingRight: 5}}
                />
              </UIPressable>
            )}
            {!hideSoundForThisReadingPlan && showSoundIcon && IS_WEB && (
              <UIPressable onPress={handleOpenPlayer}>
                <Icon
                  name={isPlayerOpen ? 'volume-off' : 'volume-1'}
                  color={
                    isPlayerOpen ? theme.colors.tabIcon : theme.colors.primary
                  }
                  size={20}
                  style={{padding: theme.hMargin, paddingRight: 5}}
                />
              </UIPressable>
            )}

            <UIPressable onPress={onProgressClick}>
              <Icon
                name="check-circle"
                color={isCompleted ? theme.colors.primary : theme.colors.icon}
                size={20}
                style={{padding: theme.hMargin}}
              />
            </UIPressable>
          </View>
        );

        return (
          <UINavHeader
            title={title}
            headerLeft={headerLeft}
            headerRight={headerRight}
          />
        );
      },
    });
  }, [isCompleted, isPlayerOpen, hideSoundForThisReadingPlan]);

  return (
    <UIScreenReaderWrapper>
      <ReadingView
        readingPlanData={readingPlanData}
        verse={readingVerse}
        disableViewPager={true}
        navigation={navigation}
        setShowSoundIcon={setShowSoundIcon}
        audioModalVisible={audioModalVisible}
        setAudioModalVisible={setAudioModalVisible}
      />
      {IS_MOBILE && <AudioPlayer />}
    </UIScreenReaderWrapper>
  );
};

export default ReadingPlanDetailsScreen;
