import {useContext, useEffect, useState} from 'react';
import VersesGridList from '../components/books/VerseGridList';
import {id, title} from '../constants/stringsAndFields';
import DataContext from '../context/dataContext';
import {extendMissingVerses} from '../utils/arrays';
import {resetStack} from '../utils/navigation';

const VersesScreen = ({navigation, route}) => {
  const {bookId, chapterId} = route.params;
  const {getVersesByChapter, getChapter, getBookByName} =
    useContext(DataContext);
  const [verses, setVerses] = useState([]);
  const [chapter, setChapter] = useState(null);
  const [book, setBook] = useState(null);

  useEffect(() => {
    if (bookId && chapterId) {
      getBookByName(bookId).then(bookVal => {
        getChapter(chapterId).then(chapterVal => {
          getVersesByChapter(chapterVal).then(versesVal => {
            const extendedVerses = extendMissingVerses(
              versesVal,
              chapterVal,
              bookVal,
            );
            if (chapterVal) setChapter(chapterVal);
            if (bookVal) setBook(bookVal);
            setVerses(extendedVerses);
          });
        });
      });
    }
  }, [bookId, chapterId]);

  const onVerseItemPress = async selectedItem => {
    resetStack(navigation, -2);
    navigation.navigate('Tabs', {
      screen: 'Biblija',
      params: {
        bookId: book[title],
        chapterId: chapter['chapterId'],
        verseId: selectedItem[id],
      },
    });
  };

  return <VersesGridList verses={verses} onItemPress={onVerseItemPress} />;
};

export default VersesScreen;
