import {ActivityIndicator, StyleSheet, Text, View} from 'react-native';
import {useTheme} from '../../theme/ThemeContext';
import Icon from './Icon';
import {isNotEmptyString} from '../../utils/other';

export const UILoadingView = ({textData}) => {
  const {theme} = useTheme();
  const styles = getStyles(theme);

  return (
    <View style={styles.container}>
      <Icon name="bible" style={styles.icon} />
      {isNotEmptyString(textData) ? (
        <Text style={styles.text}>{textData}</Text>
      ) : null}
      <ActivityIndicator size="large" color={theme.colors.icon} />
    </View>
  );
};

UILoadingView.defaultProps = {
  textData: 'Molimo pričekajte.',
};

const getStyles = theme => {
  return StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },

    icon: {
      fontSize: 30,
      color: theme.colors.primary,
      marginBottom: 30,
    },

    text: {
      color: theme.colors.textLight,
      fontSize: 18,
      fontFamily: theme.fontFamily,
      marginBottom: 30,
    },
  });
};

export default UILoadingView;
