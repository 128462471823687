import React, {useState, useEffect} from 'react';
import {View, Text, TouchableOpacity, StyleSheet, Image} from 'react-native';
import {useNavigation, useNavigationState} from '@react-navigation/native';
import Icon from '../components/ui-components/Icon';
import {useTheme} from '../theme/ThemeContext';
import globalStyle from '../theme/globalStyle';

const DEFAULT_ROUTE = 'Biblija';
const TABS_ROUTE = 'Tabs';

const SidebarContent = () => {
  const navigation = useNavigation();
  const {theme} = useTheme();
  const styles = getStyles(theme);
  const global = globalStyle(theme);

  const [currentRoute, setCurrentRoute] = useState(DEFAULT_ROUTE);

  const routeName = useNavigationState(state => {
    const tabState = state.routes.find(
      route => route.name === TABS_ROUTE,
    )?.state;
    let resolvedRoute = tabState?.routes?.[tabState.index]?.name;
    if (!resolvedRoute) {
      resolvedRoute = tabState?.routes?.[0]?.name;
    }
    return resolvedRoute ?? DEFAULT_ROUTE;
  });

  useEffect(() => {
    setCurrentRoute(routeName);
  }, [routeName]);

  const menuItems = [
    {label: 'Biblija', icon: 'home', screen: 'Biblija'},
    {label: 'Plan čitanja', icon: 'calendar', screen: 'Plan čitanja'},
    {label: 'Omiljeni', icon: 'heart', screen: 'Omiljeni citati'},
    {label: 'Bilješke', icon: 'note', screen: 'Bilješke'},
    {label: 'Postavke', icon: 'settings', screen: 'Postavke'},
  ];

  return (
    <View style={styles.sidebar}>
      <View style={styles.sidebarHeader}>
        <Image
          source={theme.images.biblijaLogoImageSource}
          resizeMode="cover"
          style={styles.sidebarLogo}
        />
        <Text style={[global.textBold, styles.sidebarHeaderText]}>
          Biblija SHP
        </Text>
      </View>

      <Image
        source={theme.images.biblijaLogoImageSource}
        resizeMode="cover"
        style={styles.sidebarBg}
      />

      <View>
        {menuItems.map(item => (
          <TouchableOpacity
            key={item.screen}
            style={styles.menuItem}
            onPress={() => navigation.navigate(item.screen)}>
            <Icon
              name={item.icon}
              color={
                currentRoute === item.screen
                  ? theme.colors.primary
                  : theme.colors.textSidebar
              }
              size={18}
            />

            <Text
              style={[
                global.textMain,
                styles.menuText,
                {
                  color:
                    currentRoute === item.screen
                      ? theme.colors.primary
                      : theme.colors.textSidebar,
                },
              ]}>
              {item.label}
            </Text>
          </TouchableOpacity>
        ))}
      </View>
    </View>
  );
};

const getStyles = theme => {
  return StyleSheet.create({
    sidebar: {
      backgroundColor: theme.colors.navBackground,
      width: 240,
      alignItems: 'center',
      paddingBottom: 30,
      paddingHorizontal: 20,
      shadowColor: theme.colors.shadow,
      shadowOpacity: 0.4,
      shadowOffset: {width: 0, height: 1},
      shadowRadius: 10,
      elevation: 5,
      zIndex: 1,
      overflow: 'hidden',
    },

    sidebarHeader: {
      alignItems: 'center',
      justifyContent: 'center',
      height: 200,
      width: 190,
      marginBottom: 40,
      borderBottomWidth: 1,
      borderBottomColor: theme.colors.border,
    },

    sidebarLogo: {
      width: 80,
      height: 80,
    },

    sidebarHeaderText: {
      fontSize: 16,
      marginTop: 10,
    },

    sidebarBg: {
      position: 'absolute',
      width: 500,
      height: 500,
      bottom: '-10%',
      left: '-44%',
      opacity: 0.1,
    },

    menuItem: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingVertical: 10,
      marginBottom: 10,
    },

    menuText: {
      marginLeft: 20,
      fontSize: 16,
    },
  });
};

export default SidebarContent;
