import {getHeaderTitle} from '@react-navigation/elements';
import {
  StackActions,
  useFocusEffect,
  useIsFocused,
} from '@react-navigation/native';
import {useCallback, useContext, useEffect, useState} from 'react';
import {BackHandler, View} from 'react-native';
import {usePlayer} from '../components/audio-player/PlayerContext';
import ReadingView from '../components/reading/ReadingView';
import Icon from '../components/ui-components/Icon';
import UINavHeader from '../components/ui-components/UINavHeader';
import {UIPressable} from '../components/ui-components/UIPressable';
import {
  additionalBook,
  audioChapterId,
  end_time,
  position,
  start_time,
} from '../constants/stringsAndFields';
import DataContext from '../context/dataContext';
import {useTheme} from '../theme/ThemeContext';
import {isNotEmpty} from '../utils/arrays';
import {setChapterTitle} from '../utils/navigation';
import {IS_MOBILE, IS_WEB} from '../utils/platform';
import {
  getBookmarkReading,
  getLastReading,
  resetBookmarkReading,
  saveBookmarkReading,
} from '../utils/storage';

const ReadingScreen = ({route, navigation}) => {
  const {
    getChapterByVerse,
    getVerse,
    getIndexByChapter,
    chapters,
    getVersesByChapter,
  } = useContext(DataContext);
  const {chapterId = null, verseId = null} = route.params ?? {};
  const [readingVerse, setReadingVerse] = useState(null);
  const [verses, setVerses] = useState([]);
  const [chapterIndex, setChapterIndex] = useState(null);
  const [restoredLastReading, setRestoredLastReading] = useState(false);
  const [bookmarkedReading, setBookmarkedReading] = useState(false);
  const [audioModalVisible, setAudioModalVisible] = useState(false);
  const [showSoundIcon, setShowSoundIcon] = useState(true);

  const [jumpToPageComplete, setJumpToPageComplete] = useState(false);
  const isFocused = useIsFocused();

  const {tabBarVisible} = route.params ?? {tabBarVisible: true};
  const [buttonsVisible, setButtonsVisible] = useState(tabBarVisible);

  useEffect(() => {
    setButtonsVisible(tabBarVisible);
  }, [tabBarVisible]);

  const {theme} = useTheme();
  const {
    isPlayerOpen,
    openPlayer,
    trackAudioPosition,
    audioPosition,
    setForceCloseAudioPlayer,
  } = usePlayer();

  const handleOpenPlayer = () => {
    if (IS_WEB) {
      setAudioModalVisible(!audioModalVisible);
    } else {
      openPlayer();
    }
  };

  const onBookmarkPress = async () => {
    if (bookmarkedReading) {
      setBookmarkedReading(false);
      await resetBookmarkReading();
    } else {
      const currentlyReading = await getLastReading();
      setBookmarkedReading(true);
      await saveBookmarkReading(currentlyReading);
    }
  };

  // First clean start
  useEffect(() => {
    if (!chapterId && !verseId && isNotEmpty(chapters)) {
      getBookmarkReading().then(bookmark => {
        getLastReading().then(lastReadingChapterId => {
          const openChapterId = bookmark ? bookmark : lastReadingChapterId;
          if (bookmark) {
            //if bookmark is not null set bookmarkedReading on true (default state is false)
            setBookmarkedReading(true);
          }
          setRestoredLastReading(true);
          const index = getIndexByChapter(openChapterId);
          setChapterTitle(index, chapters, navigation);
          setChapterIndex(index);
          setJumpToPageComplete(false);
          const readingChapter = chapters[index];
          setShowSoundIcon(!readingChapter[additionalBook]);
          getVersesByChapter(readingChapter).then(v => {
            setVerses(v);
          });
        });
      });
    }
  }, [chapterId, chapters]);

  // When navigated with Verse
  useEffect(() => {
    if (verseId) {
      getVerse(verseId).then(verse => {
        getChapterByVerse(verse).then(chapterVal => {
          setReadingVerse(verse);
          const index = getIndexByChapter(chapterVal['chapterId']);
          setChapterIndex(index);
          setJumpToPageComplete(false);
          setShowSoundIcon(!chapterVal[additionalBook]);
          getVersesByChapter(chapterVal).then(v => {
            setVerses(v);
          });
        });
      });
    }
  }, [verseId]);

  // When navigated only by chapter
  useEffect(() => {
    if (chapterId && !verseId) {
      const index = getIndexByChapter(chapterId);
      setChapterIndex(index);
      setJumpToPageComplete(false);
      const readingChapter = chapters[index];
      setShowSoundIcon(!readingChapter[additionalBook]);
      getVersesByChapter(readingChapter).then(v => {
        setVerses(v);
      });
    }
  }, [chapterId]);

  // When navigated by audioPosition
  useEffect(() => {
    if (
      isFocused &&
      trackAudioPosition &&
      audioPosition?.['chapterId'] &&
      audioPosition?.[position] &&
      verses
    ) {
      const firstSentenceEndTime = verses[1]?.[start_time];
      if (firstSentenceEndTime >= audioPosition[position]) {
        setReadingVerse(verses[0]);
      } else {
        const matchedVerse = verses.find(
          v => v[end_time] >= audioPosition[position],
        );
        if (matchedVerse) {
          matchedVerse[audioChapterId] = audioPosition['chapterId'];
          setReadingVerse(matchedVerse);
        }
      }
    }
  }, [trackAudioPosition, audioPosition, verses, isFocused]);

  useFocusEffect(
    useCallback(() => {
      const onBackPress = () => {
        if (navigation.canGoBack()) {
          navigation.dispatch(StackActions.popToTop());
        }
        BackHandler.exitApp();
      };
      const subscription = BackHandler.addEventListener(
        'hardwareBackPress',
        onBackPress,
      );
      return () => subscription.remove();
    }, [navigation]),
  );

  const onSoundIconPress = () => {
    if (isPlayerOpen) {
      setForceCloseAudioPlayer(true);
    } else {
      openPlayer();
    }
  };

  useEffect(() => {
    const headerRightButtons = (
      <View style={{flexDirection: 'row'}}>
        {showSoundIcon && IS_MOBILE && (
          <UIPressable onPress={onSoundIconPress}>
            <Icon
              name={isPlayerOpen ? 'volume-off' : 'volume-1'}
              color={isPlayerOpen ? theme.colors.tabIcon : theme.colors.primary}
              size={20}
              style={{padding: theme.hMargin, paddingRight: 5}}
            />
          </UIPressable>
        )}
        {showSoundIcon && IS_WEB && (
          <UIPressable onPress={handleOpenPlayer}>
            <Icon
              name={isPlayerOpen ? 'volume-off' : 'volume-1'}
              color={isPlayerOpen ? theme.colors.tabIcon : theme.colors.primary}
              size={20}
              style={{padding: theme.hMargin, paddingRight: 5}}
            />
          </UIPressable>
        )}
        <UIPressable
          onPress={() => navigation.navigate('Reading', {screen: 'Search'})}>
          <Icon
            name="magnifier"
            color={theme.colors.tabIcon}
            size={19}
            style={{padding: theme.hMargin}}
          />
        </UIPressable>
      </View>
    );

    const openBookChooser = () =>
      navigation.navigate('Reading', {screen: 'Books'});

    const headerLeftButtons = (
      <View style={{flexDirection: 'row'}}>
        <UIPressable onPress={openBookChooser}>
          <Icon
            name="bible"
            color={theme.colors.primary}
            size={20}
            style={{padding: theme.hMargin}}
          />
        </UIPressable>
        <UIPressable onPress={onBookmarkPress}>
          <Icon
            name="bookmark"
            color={
              bookmarkedReading ? theme.colors.primary : theme.colors.tabIcon
            }
            size={20}
            style={{padding: theme.hMargin}}
          />
        </UIPressable>
      </View>
    );

    navigation.setOptions({
      header: ({navigation, route, options}) => {
        const title = getHeaderTitle(options, route.name);
        return (
          <UINavHeader
            title={title}
            headerLeft={headerLeftButtons}
            headerRight={headerRightButtons}
          />
        );
      },
    });
  }, [showSoundIcon, isPlayerOpen, bookmarkedReading, theme]);

  return (
    <ReadingView
      readingPlanData={null}
      verse={readingVerse}
      setVerses={setVerses}
      setReadingVerse={setReadingVerse}
      chapterIndex={chapterIndex}
      jumpToPageComplete={jumpToPageComplete}
      setJumpToPageComplete={setJumpToPageComplete}
      navigation={navigation}
      restoredLastReading={restoredLastReading}
      setRestoredLastReading={setRestoredLastReading}
      setShowSoundIcon={setShowSoundIcon}
      setBookmarkedReading={setBookmarkedReading}
      audioModalVisible={audioModalVisible}
      setAudioModalVisible={setAudioModalVisible}
      buttonsVisible={buttonsVisible}
    />
  );
};

export default ReadingScreen;
