import {useNavigation} from '@react-navigation/native';
import React, {useContext, useEffect, useState} from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import {
  book_title,
  chapterId,
  chapter_chapterId,
  id,
  positionInBook,
  quote,
  timestamp,
  verseRange,
  verse_number,
} from '../../constants/stringsAndFields';
import DataContext from '../../context/dataContext';
import {useTheme} from '../../theme/ThemeContext';
import globalStyle from '../../theme/globalStyle';
import {convertTimeStampToString} from '../../utils/dateTime';
import Icon from '../ui-components/Icon';
import {UIPressable} from '../ui-components/UIPressable';

const FavoriteQuoteItem = ({quoteItem, onDeleteQuote}) => {
  const {getChapter, getVersesByChapter} = useContext(DataContext);
  const navigation = useNavigation();
  const [chapter, setChapter] = useState(null);
  const {theme} = useTheme();
  const global = globalStyle(theme);

  const handleDeleteClick = async e => {
    e.stopPropagation();
    await onDeleteQuote(quoteItem, selectedVerseText);
  };

  const openReadingScreen = async () => {
    const verses = quoteItem[verseRange];
    if (verses) {
      try {
        const startVerse = verses.split(' - ')?.[0]?.trim().slice(1);
        if (startVerse) {
          const versesObjects = await getVersesByChapter(chapter);
          const startVerseObject = versesObjects.find(
            item => item[verse_number] === Number(startVerse),
          );
          if (startVerseObject) {
            navigation.navigate('Tabs', {
              screen: 'Biblija',
              params: {
                chapterId: chapter[chapterId],
                verseId: startVerseObject[id],
              },
            });
            return;
          }
        }
      } catch (e) {
        console.log(e);
      }
    }

    if (chapter) {
      navigation.navigate('Tabs', {
        screen: 'Biblija',
        params: {
          chapterId: chapter[chapterId],
        },
      });
    }
  };

  useEffect(() => {
    getChapter(quoteItem[chapter_chapterId]).then(chapter =>
      setChapter(chapter),
    );
  }, []);

  const bookTitle = chapter?.[book_title] ?? '';
  const chapterNumber = chapter?.[positionInBook] ?? '';
  const verseRangeText =
    quoteItem?.[verseRange]?.replaceAll('V', '')?.replaceAll(' ', '') ?? '';
  const selectedVerseText = `${bookTitle} ${chapterNumber}:${verseRangeText}`;

  return (
    <TouchableOpacity style={global.listItem} onPress={openReadingScreen}>
      <View style={global.listItemTextContainer}>
        <Text style={global.listItemTitle}>{selectedVerseText}</Text>
        <Text style={global.listItemContent}>{quoteItem[quote]}</Text>
        <Text style={global.listItemDate}>
          {convertTimeStampToString(quoteItem[timestamp])}
        </Text>
      </View>
      <UIPressable onPress={handleDeleteClick} style={{padding: 5}}>
        <Icon name="trash" style={global.listItemIcon} />
      </UIPressable>
    </TouchableOpacity>
  );
};

export default FavoriteQuoteItem;
