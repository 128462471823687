import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  ACTIVE_READING_PLAN,
  BOOKMARK_CHAPTER,
  DEFAULT_LAST_READING_CHAPTER,
  DEFAULT_THEME_SETTINGS,
  LAST_LISTENING,
  LAST_LISTENING_POSITION,
  LAST_READING_CHAPTER,
  THEME_SETTINGS,
  track,
} from '../constants/stringsAndFields';

export const getLastReading = async () => {
  try {
    const data = await AsyncStorage.getItem(LAST_READING_CHAPTER);
    return data ? data : DEFAULT_LAST_READING_CHAPTER;
  } catch (e) {
    console.log(e);
  }
  return DEFAULT_LAST_READING_CHAPTER;
};

export const saveLastReading = async chapterId => {
  try {
    await AsyncStorage.setItem(LAST_READING_CHAPTER, chapterId);
  } catch (e) {
    console.log(e);
  }
};

export const getLastListening = async () => {
  try {
    const data = await AsyncStorage.getItem(LAST_LISTENING);
    if (data) return JSON.parse(data);
  } catch (e) {
    console.log(e);
  }
  return null;
};

export const saveLastListening = async (activeTrack = null, position = '0') => {
  try {
    const defaultListening = {
      [track]: '',
      [LAST_LISTENING_POSITION]: '0',
    };
    const existingListening = await getLastListening();
    if (existingListening) {
      if (activeTrack) existingListening[track] = activeTrack;
      if (position)
        existingListening[LAST_LISTENING_POSITION] = String(position);
    } else {
      if (activeTrack) defaultListening[track] = activeTrack;
      if (position)
        defaultListening[LAST_LISTENING_POSITION] = String(position);
    }
    await AsyncStorage.setItem(
      LAST_LISTENING,
      JSON.stringify(existingListening ? existingListening : defaultListening),
    );
  } catch (e) {
    console.log(e);
  }
};

export const deleteLastListening = async () => {
  await AsyncStorage.removeItem(LAST_LISTENING);
};

export const getBookmarkReading = async () => {
  try {
    const data = await AsyncStorage.getItem(BOOKMARK_CHAPTER);
    return data ? data : null;
  } catch (e) {
    console.log(e);
  }
  return null;
};

export const saveBookmarkReading = async chapterId => {
  try {
    await AsyncStorage.setItem(BOOKMARK_CHAPTER, chapterId);
  } catch (e) {
    console.log(e);
  }
};

export const getActiveReadingPlan = async () => {
  try {
    const data = await AsyncStorage.getItem(ACTIVE_READING_PLAN);
    return data ? data : null;
  } catch (e) {
    console.log(e);
  }
  return null;
};

export const setActiveReadingPlan = async readingPlanId => {
  try {
    await AsyncStorage.setItem(ACTIVE_READING_PLAN, readingPlanId);
  } catch (e) {
    console.log(e);
  }
};

export const resetBookmarkReading = async _ => {
  try {
    await AsyncStorage.removeItem(BOOKMARK_CHAPTER);
  } catch (e) {
    console.log(e);
  }
};

export const saveThemeSettings = async (
  updatedSettingKey,
  updatedSettingValue,
) => {
  try {
    const data = await AsyncStorage.getItem(THEME_SETTINGS);
    const storedSettings = data ? JSON.parse(data) : DEFAULT_THEME_SETTINGS;
    storedSettings[updatedSettingKey] = updatedSettingValue;
    await AsyncStorage.setItem(THEME_SETTINGS, JSON.stringify(storedSettings));
  } catch (e) {
    console.log(e);
  }
};

export const getThemeSettings = async () => {
  try {
    const data = await AsyncStorage.getItem(THEME_SETTINGS);
    return data ? JSON.parse(data) : DEFAULT_THEME_SETTINGS;
  } catch (e) {
    console.log(e);
  }
  return DEFAULT_THEME_SETTINGS;
};
