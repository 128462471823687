import React, {useContext, useEffect, useState} from 'react';
import DataContext from '../context/dataContext';
import {UIScreenContainer} from '../components/ui-components/UIScreenContainer';
import {UIScreenScrollWrapper} from '../components/ui-components/UIScreenScrollWrapper';
import BooksGridList from '../components/books/BooksGridList';
import UINavHeader from '../components/ui-components/UINavHeader';
import {UIPressable} from '../components/ui-components/UIPressable';
import {Text} from 'react-native';
import Icon from '../components/ui-components/Icon';
import {useTheme} from '../theme/ThemeContext';
import globalStyle from '../theme/globalStyle';
import {chapterId, title} from '../constants/stringsAndFields';
import {getHeaderTitle} from '@react-navigation/elements';
import {bookDownload} from '../utils/download';
import {addTracks} from '../../trackPlayerServices';
import UILoadingView from '../components/ui-components/UILoadingView';
import {IS_MOBILE} from '../utils/platform';

const BooksScreen = ({navigation}) => {
  const {
    books,
    getChaptersByBookTitle,
    updateBook,
    updateChapter,
    getChapters,
    refreshBooks,
    refreshChapters,
  } = useContext(DataContext);
  const [booksData, setBooksData] = useState([]);
  const [ongoingProgress, setOngoingProgress] = useState(false);
  const [selectMode, setSelectMode] = useState(false);
  const {theme} = useTheme();
  const global = globalStyle(theme);
  const [selectedBooks, setSelectedBooks] = useState([]);
  const [progressText, setProgressText] = useState();

  useEffect(() => {
    // Filter the books into Old and New Testaments
    const introBooks = books.slice(66, 70);
    const oldTestamentBooks = books.slice(0, 39);
    const newTestamentBooks = books.slice(39, 66);
    const updatedBooksData = [
      {
        data: introBooks,
      },
      {
        title: 'Stari zavjet',
        data: oldTestamentBooks,
      },
      {
        title: 'Novi zavjet',
        data: newTestamentBooks,
      },
    ];
    setBooksData(updatedBooksData);
  }, [books]);

  const startSelectMode = () => {
    setSelectMode(true);
  };

  const endSelectMode = () => {
    setSelectMode(false);
    setSelectedBooks([]);
  };

  const updateSelectedBooks = book => {
    setSelectedBooks(prevSelectedBooks => {
      if (prevSelectedBooks.includes(book)) {
        // Remove book from selected list
        return prevSelectedBooks.filter(selectedBook => selectedBook !== book);
      } else {
        // Add book to selected list
        return [...prevSelectedBooks, book];
      }
    });
  };

  const downloadSelectedBooks = async () => {
    setOngoingProgress(true);
    try {
      const downloadedChapters = [];
      for (const book of selectedBooks) {
        const bookChapters = await getChaptersByBookTitle({
          [title]: book[title],
        });
        const success = await bookDownload(
          selectedBooks,
          bookChapters,
          updateBook,
          updateChapter,
          setProgressText,
        );
        if (success) {
          downloadedChapters.push(...bookChapters);
        }
      }
      if (downloadedChapters.length > 0) {
        await refreshChapters();
        await refreshBooks();
        const chapters = await getChapters();
        await addTracks(chapters, books);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setOngoingProgress(false);
      endSelectMode();
    }
  };

  const onBookItemPress = async selectedItem => {
    navigation.navigate('Chapters', {
      bookId: selectedItem[title],
    });
  };

  const onBookSingleChapterPress = async (book, chapter) => {
    navigation.navigate('Tabs', {
      screen: 'Biblija',
      params: {
        bookId: book[title],
        chapterId: chapter[chapterId],
        verseId: null,
      },
    });
  };

  useEffect(() => {
    navigation.setOptions({
      header: ({navigation, route, options}) => {
        const title = getHeaderTitle(options, route.name);

        const headerLeft = (
          <UIPressable
            onPress={() => navigation.goBack()}
            style={{padding: theme.hMargin}}>
            <Icon name="arrow-left" color={theme.colors.text} size={18} />
          </UIPressable>
        );

        const headerRight = selectMode ? (
          <UIPressable
            onPress={downloadSelectedBooks}
            style={{padding: theme.hMargin}}>
            <Text
              style={
                global.headerButtonText
              }>{`Preuzmi (${selectedBooks.length})`}</Text>
          </UIPressable>
        ) : (
          <UIPressable
            onPress={startSelectMode}
            style={{padding: theme.hMargin}}>
            <Icon name="download" color={theme.colors.primary} size={20} />
          </UIPressable>
        );

        return (
          <UINavHeader
            title={title}
            headerLeft={headerLeft}
            headerRight={IS_MOBILE && headerRight}></UINavHeader>
        );
      },
    });
  }, [selectMode, selectedBooks]);

  return (
    <UIScreenScrollWrapper>
      <UIScreenContainer>
        {ongoingProgress ? (
          <UILoadingView textData={progressText} />
        ) : (
          booksData.map((section, index) => (
            <BooksGridList
              section={section}
              key={String(index)}
              onBookItemPress={onBookItemPress}
              onBookSingleChapterPress={onBookSingleChapterPress}
              onSelectMode={selectMode}
              selectedBooks={selectedBooks}
              onUpdateSelectedBooks={updateSelectedBooks}
            />
          ))
        )}
      </UIScreenContainer>
    </UIScreenScrollWrapper>
  );
};

export default BooksScreen;
