import {useIsFocused} from '@react-navigation/native';
import React, {useContext, useEffect, useState} from 'react';
import NotesList from '../components/note/NotesList';
import DataContext from '../context/dataContext';
import DeleteConfirmationModal from '../components/favorite-quote/DeleteConfirmationModal';
import {id} from '../constants/stringsAndFields';

const NotesScreen = ({navigation, route}) => {
  const {getNotes, deleteNote} = useContext(DataContext);
  const [notes, setNotes] = useState([]);
  const [selectedNote, setSelectedNote] = useState(null);
  const [selectedNoteText, setSelectedNoteText] = useState('');
  const [isModalVisible, setModalVisible] = useState(false);
  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused) {
      refreshNotes().catch(console.error);
    }
  }, [isFocused]);

  const refreshNotes = async () => {
    const notesDb = await getNotes();
    if (notesDb) {
      setNotes([...notesDb]);
    }
  };

  const onNoteClick = async item => {
    navigation.navigate('Reading', {
      screen: 'NotesDetail',
      params: {noteId: item[id]},
    });
  };

  const onDeleteNote = (note, selectedVerseText) => {
    setSelectedNote(note);
    setSelectedNoteText(selectedVerseText);
    setModalVisible(true);
  };

  const confirmDelete = async () => {
    await deleteNote(selectedNote);
    await refreshNotes();
    setModalVisible(false);
    setSelectedNote(null);
  };

  const cancelDelete = () => {
    setModalVisible(false);
    setSelectedNote(null);
  };

  return (
    <>
      <NotesList
        notes={notes}
        onDeleteNote={onDeleteNote}
        onNoteClick={onNoteClick}
      />
      <DeleteConfirmationModal
        visible={isModalVisible}
        onConfirm={confirmDelete}
        onCancel={cancelDelete}
        modalTitle="Brisanje bilješke"
        modalContent="Jeste li sigurni da želite obrisati bilješku za citat"
        quoteText={selectedNoteText}
      />
    </>
  );
};

export default NotesScreen;
