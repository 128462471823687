const globalStyle = theme => {
  return {
    /* Typography */

    textMain: {
      color: theme.colors.text,
      fontSize: 14,
      fontFamily: theme.fontFamily,
      lineHeight: 20,
    },

    textLarger: {
      color: theme.colors.text,
      fontSize: 16,
      fontFamily: theme.fontFamily,
      lineHeight: 22,
    },

    textBold: {
      color: theme.colors.text,
      fontSize: 14,
      fontFamily: theme.fontFamilyBold,
      lineHeight: 20,
    },

    textLight: {
      color: theme.colors.textLight,
      fontSize: 14,
      fontFamily: theme.fontFamily,
    },

    textTitle: {
      color: theme.colors.text,
      fontSize: 18,
      fontFamily: theme.fontFamilyBold,
    },

    textSubTitle: {
      color: theme.colors.text,
      fontSize: 16,
      fontFamily: theme.fontFamilyBold,
    },

    link: {
      color: theme.colors.primary,
      fontFamily: theme.fontFamily,
      fontSize: 14,
    },

    /* Layout */
    container: {
      flex: 1,
      backgroundColor: theme.colors.background,
      width: '100%',
      height: '100%',
    },

    /* List */
    listItem: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: 15,
      borderBottomWidth: 1,
      borderBottomColor: theme.colors.border,
    },

    /* ListHorizontal */
    listItemHorizontal: {
      alignItems: 'center',
      justifyContent: 'center',
      height: 75,
      minWidth: 75,
      backgroundColor: theme.colors.navBackground,
      borderWidth: 1,
      borderColor: theme.colors.border,
      borderLeftWidth: 0,
    },

    listItemHorizontalPrimary: {
      borderLeftWidth: 1,
      borderColor: theme.colors.primary,
    },

    listItemHorizontalGray: {
      backgroundColor: theme.colors.border,
    },

    listItemTextContainer: {
      flex: 1,
      marginRight: 15,
    },

    listItemTitle: {
      fontFamily: theme.fontFamilyBold,
      color: theme.colors.text,
      fontSize: 18,
      marginBottom: 4,
    },

    listItemContent: {
      fontFamily: theme.fontFamily,
      color: theme.colors.text,
      fontSize: 16,
      marginBottom: 7,
    },

    listItemDate: {
      fontFamily: theme.fontFamily,
      color: theme.colors.textLight,
      fontSize: 12,
      lineHeight: 16,
    },

    listItemIcon: {
      color: theme.colors.icon,
      fontSize: 18,
      zIndex: 1,
    },

    /* GridList */
    gridItemWrapper: {
      width: '14.28%',
    },
    gridItemWrapperIntro: {
      width: '25%',
      marginTop: 15,
    },

    gridItem: {
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: 40,
      paddingHorizontal: 2,
      margin: 2,
      borderRadius: 5,
      backgroundColor: theme.colors.navBackground,
      borderWidth: 1,
      borderColor: theme.colors.border,
    },

    gridItemText: {
      color: theme.colors.text,
      fontSize: 12,
      fontFamily: theme.fontFamily,
      textAlign: 'center',
    },

    gridTitle: {
      textAlign: 'center',
      color: theme.colors.text,
      fontFamily: theme.fontFamilyBold,
      fontSize: 16,
      marginBottom: 10,
    },

    gridSectionContainer: {
      marginBottom: 20,
    },

    gridContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
    },

    gridCheckIcon: {
      position: 'absolute',
      top: 5,
      right: 5,
    },

    headerButtonText: {
      fontFamily: theme.fontFamily,
      color: theme.colors.primary,
      fontSize: 16,
    },

    /* Global */
    flexCenter: {
      flexDirection: 'row',
      alignItems: 'center',
    },

    modalFooter: {
      flexDirection: 'row',
      justifyContent: 'center',
      borderTopColor: theme.colors.borderDark,
      borderTopWidth: 1,
      paddingTop: 20,
      marginTop: 20,
    },
  };
};

export default globalStyle;
