import {
  INTEGER,
  REAL,
  TEXT,
  book_title,
  canon_order,
  chapter,
  end_time,
  id,
  positionInBook,
  similarity,
  start_time,
  title,
  verse_number,
  verse_txt,
} from '../constants/stringsAndFields';
import {isNotEmpty} from '../utils/arrays';
import {BOOK_TABLE} from './book';
import {
  checkTableExists,
  deleteData,
  executeTransaction,
  insertData,
  selectData,
  selectDataMultiWhere,
  selectDataMultiWhereLike,
  updateData,
} from './common';
import {runSqlCmd} from './sqlOperations';

export const VERSE_TABLE = 'Verse';
const keys = [
  id,
  canon_order,
  book_title,
  chapter,
  verse_number,
  verse_txt,
  start_time,
  end_time,
  similarity,
];

// TODO: add index which is used for query
export const createVerseTable = async tx => {
  await runSqlCmd(tx, `DROP TABLE IF EXISTS ${VERSE_TABLE};`);
  await runSqlCmd(
    tx,
    `CREATE TABLE IF NOT EXISTS ${VERSE_TABLE}(
      ${id} ${TEXT} PRIMARY KEY NOT NULL,
      ${canon_order} ${TEXT},
      ${book_title} ${TEXT},
      ${chapter} ${INTEGER},
      ${verse_number} ${INTEGER},
      ${verse_txt} ${TEXT},
      ${start_time} ${REAL},
      ${end_time} ${REAL},
      ${similarity} ${REAL},
      FOREIGN KEY(${book_title}) REFERENCES ${BOOK_TABLE}(${title}) ON UPDATE NO ACTION ON DELETE NO ACTION);`,
  );
};

export const reCreateVerseTableAndData = async (tx, cmds) => {
  await createVerseTable(tx);
  await executeTransaction(tx, cmds);
};

export const insertVerse = async (tx, chapter) => {
  await insertData(tx, VERSE_TABLE, keys, chapter);
};

export const deleteAllVerses = async tx => {
  await deleteData(tx, VERSE_TABLE);
};

export const deleteVerse = async (tx, verse) => {
  await deleteData(tx, VERSE_TABLE, verse[id]);
};

export const updateVerse = async (tx, verse) => {
  await updateData(tx, VERSE_TABLE, verse, id);
};

export const getVerses = async tx => {
  const verses = await selectData(tx, VERSE_TABLE, keys);
  return isNotEmpty(verses) ? verses : [];
};

export const getVerse = async (tx, dataId) => {
  const verses = await selectData(
    tx,
    VERSE_TABLE,
    keys,
    id,
    dataId,
    [],
    false,
    'LIMIT 1',
  );
  return isNotEmpty(verses) ? verses[0] : null;
};

export const checkVerseTable = async tx => {
  return await checkTableExists(tx, VERSE_TABLE);
};

export const getVersesByChapter = async (tx, chapterVal) => {
  const verses = await selectDataMultiWhere(
    tx,
    VERSE_TABLE,
    keys,
    [book_title, chapter],
    [chapterVal[book_title], chapterVal[positionInBook]],
    [verse_number, 'ASC'],
    false,
  );
  return verses;
};

export const getVersesByQueryAndTestamentFromBible = async (
  tx,
  query,
  bookTestament,
) => {
  const words = query.split(' ');
  const verses = await selectDataMultiWhereLike(
    tx,
    VERSE_TABLE,
    keys,
    [verse_txt],
    [...words],
    [],
    false,
    bookTestament,
  );
  return verses;
};
