import {
  REAL,
  TEXT,
  book_title,
  chapterId,
  end_time,
  sentenceId,
  similarity,
  start_time,
} from '../constants/stringsAndFields';
import {isNotEmpty} from '../utils/arrays';
import {CHAPTER_TABLE} from './chapter';
import {
  checkTableExists,
  executeTransaction,
  selectData,
  selectDataOrderParsed,
} from './common';
import {runSqlCmd} from './sqlOperations';

export const INTRODUCTION_SENTENCES_TABLE = 'IntroductionSentences';
const keys = [
  sentenceId,
  chapterId,
  book_title,
  start_time,
  end_time,
  similarity,
];

export const createIntroductionSentencesTable = async tx => {
  await runSqlCmd(tx, `DROP TABLE IF EXISTS ${INTRODUCTION_SENTENCES_TABLE};`);
  await runSqlCmd(
    tx,
    `CREATE TABLE IF NOT EXISTS ${INTRODUCTION_SENTENCES_TABLE}(
      ${sentenceId} ${TEXT},
      ${chapterId} ${TEXT},
      ${book_title} ${TEXT},
      ${start_time} ${REAL},
      ${end_time} ${REAL},
      ${similarity} ${REAL},
      PRIMARY KEY (${sentenceId}, ${book_title}),
      FOREIGN KEY(${chapterId}) REFERENCES ${CHAPTER_TABLE}(${chapterId}) ON UPDATE NO ACTION ON DELETE NO ACTION);`,
  );
};

export const reCreateIntroductionSentencesTableAndData = async (tx, cmds) => {
  await createIntroductionSentencesTable(tx);
  await executeTransaction(tx, cmds);
};

export const getIntroductionSentences = async tx => {
  const introductionSentences = await selectData(
    tx,
    INTRODUCTION_SENTENCES_TABLE,
    keys,
  );
  return isNotEmpty(introductionSentences) ? introductionSentences : [];
};

export const getIntroductionSentence = async (tx, dataId) => {
  const introductionSentences = await selectData(
    tx,
    INTRODUCTION_SENTENCES_TABLE,
    keys,
    sentenceId,
    dataId,
    [],
    false,
    'LIMIT 1',
  );
  return isNotEmpty(introductionSentences) ? introductionSentences[0] : null;
};

export const checkIntroductionSententesTable = async tx => {
  return await checkTableExists(tx, INTRODUCTION_SENTENCES_TABLE);
};

export const getIntroductionSentencesByChapter = async (tx, chapterVal) => {
  const introductionSentences = await selectDataOrderParsed(
    tx,
    INTRODUCTION_SENTENCES_TABLE,
    keys,
    chapterId,
    chapterVal[chapterId],
    [sentenceId, 'ASC'],
  );
  return introductionSentences;
};
