import {useIsFocused} from '@react-navigation/native';
import React, {useContext, useEffect, useState} from 'react';
import FavoriteQuoteList from '../components/favorite-quote/FavoriteQuoteList';
import DataContext from '../context/dataContext';
import DeleteConfirmationModal from '../components/favorite-quote/DeleteConfirmationModal';

const FavoritesScreen = ({navigation, route}) => {
  const {getFavoriteQuotes, deleteFavoriteQuote} = useContext(DataContext);
  const [favoriteQuotes, setFavoriteQuotes] = useState([]);
  const [selectedQuote, setSelectedQuote] = useState(null);
  const [selectedQuoteText, setSelectedQuoteText] = useState('');
  const [isModalVisible, setModalVisible] = useState(false);
  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused) {
      refreshQuotes().catch(console.error);
    }
  }, [isFocused]);

  const refreshQuotes = async () => {
    const quotesDb = await getFavoriteQuotes();
    if (quotesDb) {
      setFavoriteQuotes([...quotesDb]);
    }
  };

  const onDeleteQuote = (quote, selectedVerseText) => {
    setSelectedQuote(quote);
    setSelectedQuoteText(selectedVerseText);
    setModalVisible(true);
  };

  const confirmDelete = async () => {
    await deleteFavoriteQuote(selectedQuote);
    await refreshQuotes();
    setModalVisible(false);
    setSelectedQuote(null);
  };

  const cancelDelete = () => {
    setModalVisible(false);
    setSelectedQuote(null);
  };

  return (
    <>
      <FavoriteQuoteList
        quotes={favoriteQuotes}
        onDeleteQuote={onDeleteQuote}
      />

      <DeleteConfirmationModal
        visible={isModalVisible}
        onConfirm={confirmDelete}
        onCancel={cancelDelete}
        modalTitle="Brisanje omiljenog stiha"
        modalContent="Jeste li sigurni da želite obrisati iz liste omiljenih citata"
        quoteText={selectedQuoteText}
      />
    </>
  );
};

export default FavoritesScreen;
