import React, {createContext, useEffect, useState} from 'react';
import {
  getDailyReadings as getDailyReadingsDb,
  getDailyReading as getDailyReadingDb,
  resetReadingPlan as resetReadingPlanDb,
  setReadingCompletedAndSave as setReadingCompletedAndSaveDb,
  getReadingPlanProgresses,
  getReadingPlanProgress as getReadingPlanProgressDb,
} from '../database/readingPlanProgress';
import {
  getBooks,
  getBook as getBookDb,
  updateBook as updateBookDb,
} from '../database/book';
import {
  getVersesByChapter as getVersesByChapterDb,
  getVersesByQueryAndTestamentFromBible as getVersesByQueryAndTestamentFromBibleDb,
  getVerses as getVersesDb,
  getVerse as getVerseDb,
} from '../database/verse';
import {
  getChapterByVerse as getChapterByVerseDb,
  getChaptersByBookTitle as getChaptersByBookTitleDb,
  getChapter as getChapterDb,
  getChapters as getChaptersDb,
  getChapterByBookTitleAndChapter as getChapterByBookTitleAndChapterDb,
  getNextChapter as getNextChapterDb,
  getPreviousChapter as getPreviousChapterDb,
  updateChapter as updateChapterDb,
} from '../database/chapter';
import {getFootnote as getFootnoteDb} from '../database/footnote';
import {
  deleteNote as deleteNoteDb,
  insertNote,
  getNotes as getNotesDb,
  getNote as getNoteDb,
  updateNote as updateNoteDb,
} from '../database/note';
import {
  insertFavoriteQuote,
  deleteFavoriteQuote as deleteFavoriteQuoteDb,
  getFavoriteQuotes as getFavoriteQuotesDb,
} from '../database/favoriteQuote';
import {getIndexByChapterId} from '../utils/arrays';
import {getIntroductionSentencesByChapter as getIntroductionSentencesByChapterDb} from '../database/introductionSentences';
import {isIntroBook} from '../constants/stringsAndFields';

const DataContext = createContext({
  onThemeChange: () => {},
});

export function DataProvider(props) {
  const {db} = props;
  const [readingPlans, setReadingPlans] = useState([]);
  const [books, setBooks] = useState([]);
  const [hideTabBar, setHideTabBar] = useState(false);
  const [chapters, setChapters] = useState([]);
  const [initDataLoaded, setInitDataLoaded] = useState(false);
  const [showConnectionWarning, setShowConnectionWarning] = useState(false);

  useEffect(() => {
    if (db) {
      appStartupInitData().catch(console.error);
    }
  }, [db]);

  const appStartupInitData = async () => {
    await refreshChapters();
    await refreshReadingPlans();
    await refreshBooks();
    setInitDataLoaded(true);
  };

  const refreshChapters = async () => {
    const chaptersDb = await getChapters();
    setChapters(chaptersDb);
  };

  const refreshReadingPlans = async () => {
    const plans = await getReadingPlanProgresses(db);
    setReadingPlans(plans);
  };

  const refreshBooks = async () => {
    const books = await getBooks(db);
    setBooks(books);
  };

  const getReadingPlanProgress = async readingPlanProgressId => {
    const readings = await getReadingPlanProgressDb(db, readingPlanProgressId);
    return readings;
  };

  const getVerse = async verseId => {
    const verses = await getVerseDb(db, verseId);
    return verses;
  };

  const getVerses = async () => {
    const verses = await getVersesDb(db);
    return verses;
  };

  const getVersesByChapter = async chapter => {
    let verses = [];
    if (chapter) {
      if (chapter?.[isIntroBook] === 1) {
        verses = await getIntroductionSentencesByChapterDb(db, chapter);
      } else {
        verses = await getVersesByChapterDb(db, chapter);
      }
    }
    return verses;
  };

  const getDailyReadings = async readingPlanIdValue => {
    const dailyReadings = await getDailyReadingsDb(db, readingPlanIdValue);
    return dailyReadings;
  };

  const getDailyReading = async (readingPlanIdValue, readingEntryIdValue) => {
    const dailyReading = await getDailyReadingDb(
      db,
      readingPlanIdValue,
      readingEntryIdValue,
    );
    return dailyReading;
  };

  const resetReadingPlan = async readingPlanId => {
    await resetReadingPlanDb(db, readingPlanId);
    //await refreshReadingPlans();
  };

  const setReadingCompletedAndSave = async (data, isCompleted) => {
    await setReadingCompletedAndSaveDb(db, data, isCompleted);
    //await refreshReadingPlans();
  };

  const getChaptersByBookTitle = async book => {
    const chapters = await getChaptersByBookTitleDb(db, book);
    return chapters;
  };

  const getFootnote = async footnoteId => {
    const footnote = await getFootnoteDb(db, footnoteId);
    return footnote;
  };

  const getVersesByQueryAndTestamentFromBible = async (
    query,
    bookTestament,
  ) => {
    const verses = await getVersesByQueryAndTestamentFromBibleDb(
      db,
      query,
      bookTestament,
    );
    return verses;
  };

  const getChapterByVerse = async verse => {
    const chapter = await getChapterByVerseDb(db, verse);
    return chapter;
  };

  const getChapter = async chapterId => {
    const chapter = await getChapterDb(db, chapterId);
    return chapter;
  };

  const getChapters = async _ => {
    const chapters = await getChaptersDb(db);
    return chapters;
  };

  const getIndexByChapter = chapterId => {
    return getIndexByChapterId(chapters, chapterId);
  };

  const getChapterByBookTitleAndChapter = async (bookTitle, chapterNumber) => {
    const chapter = await getChapterByBookTitleAndChapterDb(
      db,
      bookTitle,
      chapterNumber,
    );
    return chapter;
  };

  const getNextChapter = async (chapterId, isBibleChapter) => {
    const chapter = await getNextChapterDb(db, chapterId, isBibleChapter);
    return chapter;
  };

  const getPreviousChapter = async (chapterId, isBibleChapter) => {
    const chapter = await getPreviousChapterDb(db, chapterId, isBibleChapter);
    return chapter;
  };

  const getBookByName = async bookName => {
    const book = await getBookDb(db, bookName);
    return book;
  };

  const saveNote = async note => {
    await insertNote(db, note);
  };

  const deleteNote = async note => {
    await deleteNoteDb(db, note);
  };

  const getNote = async noteId => {
    const note = await getNoteDb(db, noteId);
    return note;
  };

  const getNotes = async () => {
    const notes = await getNotesDb(db);
    return notes;
  };

  const saveFavoriteQuote = async favQuote => {
    await insertFavoriteQuote(db, favQuote);
  };

  const deleteFavoriteQuote = async note => {
    await deleteFavoriteQuoteDb(db, note);
  };

  const getFavoriteQuotes = async () => {
    const favQuotes = await getFavoriteQuotesDb(db);
    return favQuotes;
  };

  const updateChapter = async chapter => {
    await updateChapterDb(db, chapter);
  };

  const updateBook = async book => {
    await updateBookDb(db, book);
  };

  const updateNote = async note => {
    await updateNoteDb(db, note);
  };

  const getIntroductionSentencesByChapter = async chapter => {
    const sentences = await getIntroductionSentencesByChapterDb(db, chapter);
    return sentences;
  };

  return (
    <DataContext.Provider
      value={{
        readingPlans,
        books,
        getReadingPlanProgress,
        getVerse,
        getVerses,
        refreshReadingPlans,
        getDailyReadings,
        getDailyReading,
        resetReadingPlan,
        setReadingCompletedAndSave,
        getChaptersByBookTitle,
        getFootnote,
        getVersesByChapter,
        getVersesByQueryAndTestamentFromBible,
        getChapterByVerse,
        getChapter,
        getChapters,
        getChapterByBookTitleAndChapter,
        getBookByName,
        saveNote,
        saveFavoriteQuote,
        getNextChapter,
        getPreviousChapter,
        deleteNote,
        deleteFavoriteQuote,
        getNote,
        getNotes,
        getFavoriteQuotes,
        chapters,
        getIndexByChapter,
        updateChapter,
        updateBook,
        initDataLoaded,
        refreshBooks,
        refreshChapters,
        hideTabBar,
        setHideTabBar,
        updateNote,
        showConnectionWarning,
        setShowConnectionWarning,
        getIntroductionSentencesByChapter,
      }}>
      {props.children}
    </DataContext.Provider>
  );
}

export const DataConsumer = DataContext.Consumer;
export default DataContext;
