import {useContext, useEffect, useState} from 'react';
import DataContext from '../context/dataContext';
import ChaptersGridList from '../components/books/ChaptersGridList';
import {chapterId, positionInBook, title} from '../constants/stringsAndFields';
import {resetStack} from '../utils/navigation';

const ChaptersScreen = ({navigation, route}) => {
  const {bookId} = route.params;
  const {getChaptersByBookTitle, getBookByName} = useContext(DataContext);
  const [chapters, setChapters] = useState([]);
  const [book, setBook] = useState(null);

  useEffect(() => {
    if (bookId) {
      getBookByName(bookId).then(res => {
        setBook(res);
      });
    }
  }, [bookId]);

  useEffect(() => {
    if (book) {
      getChaptersByBookTitle(book).then(res => {
        setChapters(res);
      });
    }
  }, [book]);

  const onChapterItemPress = async selectedItem => {
    if (selectedItem[positionInBook] === 0) {
      try {
        resetStack(navigation, -2);
      } catch (e) {
        console.log(e);
      }
      navigation.navigate('Tabs', {
        screen: 'Biblija',
        params: {
          bookId: book[title],
          chapterId: selectedItem[chapterId],
          verseId: null,
        },
      });
    } else {
      navigation.navigate('Verses', {
        bookId: book[title],
        chapterId: selectedItem[chapterId],
      });
    }
  };

  return (
    <ChaptersGridList chapters={chapters} onItemPress={onChapterItemPress} />
  );
};

export default ChaptersScreen;
