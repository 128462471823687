import {
  COMMENT,
  CREATED_AT,
  INTEGER,
  REAL,
  TEXT,
  book_title,
  chapter,
  end_time,
  id,
  similarity,
  start_time,
  timestamp,
  verseRange,
  verse_number,
} from '../constants/stringsAndFields';
import {
  checkColumnExist,
  checkTableExists,
  insertData,
  selectData,
} from './common';
import {FAVORITE_QUOTE_TABLE, checkFavoriteQuoteTable} from './favoriteQuote';
import {NOTE_TABLE, checkNoteTable} from './note';
import {runSqlCmd} from './sqlOperations';
import {checkVerseTable, VERSE_TABLE} from './verse';

export const VERSIONS_TABLE = 'versions';

const keys = [id, COMMENT, CREATED_AT];

export const initVersionsTable = async tx => {
  const exist = await checkVersionsTable(tx);
  if (!exist) {
    await reCreateVersionsTable(tx);
  }
};

export const checkOtherMigrations = async tx => {
  try {
    const migrationApplied = [];
    const migrationThreeApplied = await isMigrationApplied(3, tx);
    if (!migrationThreeApplied) {
      await migration3(tx);
      await insertVersion(tx, {
        id: 3,
        [COMMENT]: 'FAVORITE_QUOTE_TABLE table timestamp field',
        [CREATED_AT]: null,
      });
      console.log('Migration 3 is applied');
    }
    const migrationFourApplied = await isMigrationApplied(4, tx);
    if (!migrationFourApplied) {
      await migration4(tx);
      await insertVersion(tx, {
        id: 4,
        [COMMENT]: 'VERSE_TABLE table start, end, similarity field',
        [CREATED_AT]: null,
      });
      migrationApplied.push(4);
      console.log('Migration 4 is applied');
    }
    // migration 5 is table data load
    const migrationSixApplied = await isMigrationApplied(6, tx);
    if (!migrationSixApplied) {
      await migration6(tx);
      await insertVersion(tx, {
        id: 6,
        [COMMENT]: 'FAVORITE_QUOTE and NOTES verseRange field',
        [CREATED_AT]: null,
      });
      console.log('Migration 6 is applied');
    }
    return migrationApplied;
  } catch (e) {
    console.log(e);
  }
};

export const reCreateVersionsTable = async tx => {
  await runSqlCmd(tx, `DROP TABLE IF EXISTS ${VERSIONS_TABLE};`);
  await runSqlCmd(
    tx,
    `CREATE TABLE IF NOT EXISTS ${VERSIONS_TABLE}(
      ${id} INTEGER PRIMARY KEY NOT NULL,
      ${COMMENT} TEXT,
      ${CREATED_AT} TEXT);`,
  );
  const initVersion = {id: 1, [COMMENT]: 'init', [CREATED_AT]: null};
  await insertVersion(tx, initVersion);
};

export const insertVersion = async (tx, version) => {
  await insertData(tx, VERSIONS_TABLE, keys, version);
};

export const checkVersionsTable = async tx => {
  return await checkTableExists(tx, VERSIONS_TABLE);
};

export const isMigrationApplied = async (versionId, tx) => {
  const version = await getVersion(versionId, tx);
  return version && version.length > 0 ? true : false;
};
export const getVersion = async (versionId, tx) => {
  const versions = await selectData(
    tx,
    VERSIONS_TABLE,
    keys,
    id,
    versionId,
    id,
    null,
    'LIMIT 1',
  );
  return versions;
};

const migration3 = async tx => {
  const tableExist = await checkFavoriteQuoteTable(tx);
  if (tableExist) {
    let updatedColumnExists = await checkColumnExist(
      tx,
      FAVORITE_QUOTE_TABLE,
      timestamp,
    );
    if (!updatedColumnExists) {
      await runSqlCmd(
        tx,
        `ALTER TABLE ${FAVORITE_QUOTE_TABLE} ADD COLUMN ${timestamp} ${INTEGER};`,
      );
      updatedColumnExists = await checkColumnExist(
        tx,
        FAVORITE_QUOTE_TABLE,
        timestamp,
      );
      updatedColumnExists && console.log('Migration 2.1 applied successfully');
    }
  }
};

const migration4 = async tx => {
  const tableExist = await checkVerseTable(tx);
  if (tableExist) {
    let updatedColumnExists = await checkColumnExist(
      tx,
      VERSE_TABLE,
      start_time,
    );
    if (!updatedColumnExists) {
      await runSqlCmd(
        tx,
        `ALTER TABLE ${VERSE_TABLE} ADD COLUMN ${start_time} ${REAL};`,
      );
      updatedColumnExists = await checkColumnExist(tx, VERSE_TABLE, start_time);
      updatedColumnExists && console.log('Migration 4.1 applied successfully');
    }

    updatedColumnExists = await checkColumnExist(tx, VERSE_TABLE, end_time);
    if (!updatedColumnExists) {
      await runSqlCmd(
        tx,
        `ALTER TABLE ${VERSE_TABLE} ADD COLUMN ${end_time} ${REAL};`,
      );
      updatedColumnExists = await checkColumnExist(tx, VERSE_TABLE, end_time);
      updatedColumnExists && console.log('Migration 4.2 applied successfully');
    }

    updatedColumnExists = await checkColumnExist(tx, VERSE_TABLE, similarity);
    if (!updatedColumnExists) {
      await runSqlCmd(
        tx,
        `ALTER TABLE ${VERSE_TABLE} ADD COLUMN ${similarity} ${REAL};`,
      );
      updatedColumnExists = await checkColumnExist(tx, VERSE_TABLE, similarity);
      updatedColumnExists && console.log('Migration 4.3 applied successfully');
    }
    await runSqlCmd(
      tx,
      `CREATE INDEX ${chapter}_${VERSE_TABLE}_idx ON ${VERSE_TABLE} (${chapter});
       CREATE INDEX ${book_title}_${VERSE_TABLE}_idx ON ${VERSE_TABLE} (${book_title});
       CREATE INDEX ${verse_number}_${VERSE_TABLE}_idx ON ${VERSE_TABLE} (${verse_number});
      `,
    );
    console.log('Migration 4.4 applied successfully');
  }
};

const migration6 = async tx => {
  const tableExist = await checkFavoriteQuoteTable(tx);
  if (tableExist) {
    let updatedColumnExists = await checkColumnExist(
      tx,
      FAVORITE_QUOTE_TABLE,
      verseRange,
    );
    if (!updatedColumnExists) {
      await runSqlCmd(
        tx,
        `ALTER TABLE ${FAVORITE_QUOTE_TABLE} ADD COLUMN ${verseRange} ${TEXT};`,
      );
      updatedColumnExists = await checkColumnExist(
        tx,
        FAVORITE_QUOTE_TABLE,
        verseRange,
      );
      updatedColumnExists && console.log('Migration 6.1 applied successfully');
    }
  }
  const tableExist2 = await checkNoteTable(tx);
  if (tableExist2) {
    let updatedColumnExists = await checkColumnExist(
      tx,
      NOTE_TABLE,
      verseRange,
    );
    if (!updatedColumnExists) {
      await runSqlCmd(
        tx,
        `ALTER TABLE ${NOTE_TABLE} ADD COLUMN ${verseRange} ${TEXT};`,
      );
      updatedColumnExists = await checkColumnExist(tx, NOTE_TABLE, verseRange);
      updatedColumnExists && console.log('Migration 6.2 applied successfully');
    }
  }
};
