import {useContext, useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {book_title, positionInBook} from '../../constants/stringsAndFields';
import DataContext from '../../context/dataContext';
import {useTheme} from '../../theme/ThemeContext';
import globalStyle from '../../theme/globalStyle';
import {generateNote} from '../../utils/notes';
import UIButton from '../ui-components/UIButton';
import UIModal from '../ui-components/UIModal';
import UITextInput from '../ui-components/UITextInput';

const EditNoteModal = ({
  chapter = null,
  currentIndex = 0,
  modalVisible,
  setModalVisible,
  selectedVerses = '',
}) => {
  const {saveNote, getBookByName, chapters} = useContext(DataContext);
  const [noteText, setNoteText] = useState('');

  const {theme} = useTheme();
  const global = globalStyle(theme);

  const onEditNote = text => {
    setNoteText(text);
  };
  const closeModal = () => {
    setNoteText('');
    setModalVisible(!modalVisible);
  };

  const saveNoteText = async () => {
    try {
      let noteChapter = chapter;
      if (!chapter) {
        const readingChapter = chapters[currentIndex];
        if (readingChapter) {
          noteChapter = readingChapter;
        }
      }
      await generateNote(
        noteChapter,
        noteText,
        getBookByName,
        saveNote,
        selectedVerses,
      );
    } catch (e) {
      console.log(e);
    } finally {
      closeModal();
    }
  };

  const bookTitle = chapters?.[currentIndex]?.[book_title] ?? '';
  const chapterNumber = chapters?.[currentIndex]?.[positionInBook] ?? '';
  const verseRange =
    selectedVerses?.replaceAll('V', '')?.replaceAll(' ', '') ?? '';
  const selectedVerseText = `${bookTitle} ${chapterNumber}:${verseRange}`;

  return (
    <UIModal
      visible={modalVisible}
      onClose={() => setModalVisible(!modalVisible)}
      modalTitle={'Dodaj bilješku za:'}
      titleStyle={styles.titleStyle}>
      <Text style={global.textTitle}>{selectedVerseText}</Text>
      <UITextInput
        onChangeText={onEditNote}
        value={noteText}
        style={styles.input}
        multiline={true}
      />
      <View style={[global.modalFooter, styles.modalFooter]}>
        <UIButton
          title="Odustani"
          type="outline"
          style={styles.btn}
          onPress={closeModal}
        />
        <UIButton title="Spremi" style={styles.btn} onPress={saveNoteText} />
      </View>
    </UIModal>
  );
};

const styles = StyleSheet.create({
  titleStyle: {
    paddingBottom: 10,
  },

  btn: {
    marginHorizontal: 5,
    minWidth: 110,
  },

  input: {
    marginTop: 20,
  },

  modalFooter: {
    borderTopWidth: 0,
    marginTop: 0,
  },
});

export default EditNoteModal;
