import React from 'react';
import {View, Text, TouchableOpacity, StyleSheet} from 'react-native';
import UIModal from '../ui-components/UIModal';
import globalStyle from '../../theme/globalStyle';
import {useTheme} from '../../theme/ThemeContext';
import Icon from '../ui-components/Icon';

const ReadingPlanChooser = ({visible, onClose, currentReadingPlanID}) => {
  const {theme} = useTheme();
  const global = globalStyle(theme);
  const styles = getStyles(theme);

  return (
    <UIModal
      modalTitle={'Odaberi plan čitanja:'}
      titleStyle={styles.titleStyle}
      visible={visible}
      onClose={() => onClose(currentReadingPlanID)}>
      <TouchableOpacity
        style={[
          styles.chooserRow,
          currentReadingPlanID === '1' && styles.chooserRowActive,
        ]}
        onPress={() => onClose('1')}>
        <Text style={[global.textMain, styles.chooserText]}>Standardni</Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={[
          styles.chooserRow,
          currentReadingPlanID === '2' && styles.chooserRowActive,
        ]}
        onPress={() => onClose('2')}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <Text style={[global.textMain, styles.chooserText]}>
            Tematski (s audio knjigama)
          </Text>
          <Icon
            name="volume-1"
            color={
              currentReadingPlanID === '2'
                ? theme.colors.primary
                : theme.colors.tabIcon
            }
            style={{fontSize: 16, width: 20, marginLeft: 8}}
          />
        </View>
      </TouchableOpacity>
    </UIModal>
  );
};

const getStyles = theme => {
  return StyleSheet.create({
    titleStyle: {paddingBottom: 15},

    chooserRow: {
      paddingVertical: 10,
      paddingHorizontal: 20,
      borderRadius: 5,
      marginVertical: 5,
      borderWidth: 1,
      borderColor: theme.colors.border,
    },

    chooserRowActive: {
      borderColor: theme.colors.primary,
    },

    chooserText: {
      fontSize: 16,
    },

    chooserTextActive: {
      fontFamily: theme.fontFamily,
      color: theme.colors.text,
    },
  });
};

export default ReadingPlanChooser;
