import {chapter, content, title} from '../constants/stringsAndFields';

export const resolveReadingContent = async (
  reading,
  getBookByName,
  getChapterByBookTitleAndChapter,
) => {
  //console.log(`resolveReadingContent ${reading}`);
  const readingContents = [];
  const readingContent = {chapter: null, content: null};

  if (!reading.includes(':')) {
    const multipleReadings = [];
    // this is default for single reading
    multipleReadings.push(reading);

    // check if there is a chapter range
    if (reading.includes('-')) {
      const lastWhiteSpace = reading.lastIndexOf(' ');
      const readingWithoutChapter = reading.substring(0, lastWhiteSpace).trim();
      const chapterReading = reading
        .substring(lastWhiteSpace, reading.length)
        .trim();
      const readingParts = chapterReading.trim().split('-');
      //console.log('resolveReadingContent chapterReading', chapterReading);
      //console.log('resolveReadingContent readingParts', readingParts);

      if (readingParts.length > 1) {
        const startChapter = parseInt(readingParts[0]);
        const endChapter = parseInt(readingParts[1]);
        multipleReadings.length = 0;

        for (let i = startChapter; i <= endChapter; i++) {
          multipleReadings.push(`${readingWithoutChapter} ${i}`);
        }
      } else {
        throw new ReadingParserException(
          'Invalid chapter range, invalid start or end number',
        );
      }
    }

    //console.log('Readings by chapter', multipleReadings);
    // Psalmi 52, Psalmi 53, Psalmi 54
    let counter = 0;

    for (let singleReading of multipleReadings) {
      singleReading = singleReading.trim();
      //console.log('resolveReadingContent singleReading', singleReading);

      const lastWhiteSpace = singleReading.lastIndexOf(' ');
      const readingWithoutChapter = singleReading
        .substring(0, lastWhiteSpace)
        .trim();
      const chapterReading = singleReading
        .substring(lastWhiteSpace, singleReading.length)
        .trim();
      //console.log('resolveReadingContent readingWithoutChapter',readingWithoutChapter,);
      //console.log('resolveReadingContent chapterReading', chapterReading);
      // I need at least book name and chapter
      let chapterNumber;

      try {
        chapterNumber = parseInt(chapterReading);
      } catch (e) {
        throw new ReadingParserException(
          `Could not resolve chapter number name: ${e.message}`,
        );
      }
      console.log('resolveReadingContent 1 chapterNumber', chapterNumber);

      // looking for book name
      const book = await getBookByName(readingWithoutChapter);
      //console.log('resolveReadingContent 1 book', book);
      //console.log('resolveReadingContent 1 book.title', book[title]);
      if (book != null) {
        const chapterContent = await getChapterByBookTitleAndChapter(
          book[title],
          chapterNumber,
        );

        //console.log('Found chapter content', chapterContent);
        //console.log( `resolveReadingContent chapterContent ${chapterContent[content]}`,);

        if (readingContent[chapter] == null) {
          readingContent[chapter] = chapterContent;
        }

        if (counter > 0) {
          // prepend Chapter HTML number title
          const chapterTitleHtml = `<div class="s">${readingWithoutChapter} ${chapterNumber}</div>`;
          readingContents.push(chapterTitleHtml + chapterContent[content]);
        } else {
          readingContents.push(chapterContent[content]);
        }
      } else {
        throw new ReadingParserException(
          `Could not resolve book name: ${reading}`,
        );
      }
      //console.log('readingContents ', readingContents);
      //console.log('counter ', counter);
      counter++;
    }
  } else {
    // Psalmi 119:1-24
    //console.log('else reading ', reading);
    reading = reading.trim();
    //console.log('else reading', reading);
    const lastWhiteSpace = reading.lastIndexOf(' ');
    const readingWithoutChapter = reading.substring(0, lastWhiteSpace).trim();
    //console.log('else readingWithoutChapter', readingWithoutChapter);
    // 119:1-24
    const chapterReadingFull = reading
      .substring(lastWhiteSpace, reading.length)
      .trim();
    //console.log('else chapterReadingFull', chapterReadingFull);
    // 119:1-24
    const chapterParts = chapterReadingFull.split(':');
    const versesReading = chapterParts[chapterParts.length - 1].trim();
    //console.log('else versesReading', versesReading);
    const chapter = chapterParts[0].trim();
    //console.log('else chapter', chapter);
    let chapterNumber;

    try {
      chapterNumber = parseInt(chapterParts[0]);
    } catch (e) {
      throw new ReadingParserException(
        `Could not resolve chapter number name: ${e.message}`,
      );
    }
    //console.log('else readingWithoutChapter', readingWithoutChapter);
    //console.log('else chapterNumber', chapterNumber);

    // looking for book name
    const book = await getBookByName(readingWithoutChapter);
    if (book != null) {
      //console.log('else book', book);
      //console.log('else book.title', book[title]);
      const chapterContent = await getChapterByBookTitleAndChapter(
        book[title],
        chapterNumber,
      );
      //console.log('else chapterContent', chapterContent);
      if (readingContent[chapter] == null) {
        readingContent[chapter] = chapterContent;
      }
      //console.log(`else readingContent ${readingContent}`);
      //console.log(`else readingContent.chapter ${readingContent[chapter]}`);

      const versesParts = versesReading.split('-');
      //console.log('else 2 versesParts', versesParts);
      if (versesParts.length > 1) {
        const startVerse = parseInt(versesParts[0]);
        const endVerse = parseInt(versesParts[1]);
        const rangedContent = getVerseRange(
          startVerse,
          endVerse,
          chapterContent[content],
        );
        //console.log('else rangedContent', rangedContent);
        readingContents.push(rangedContent);
        //console.log('else readingContents', readingContents);
      } else {
        throw new ReadingParserException(
          'Invalid verse range, invalid start or end number',
        );
      }
    } else {
      throw new ReadingParserException(
        `Could not resolve book name: ${reading}`,
      );
    }
  }

  readingContent[content] = readingContents.join('');
  //console.log('else readingContent[content]', readingContent[content]);
  //console.log('else readingContent', readingContent);
  return readingContent;
};

class ReadingParserException extends Error {
  constructor(message) {
    super(message);
    this.name = 'ReadingParserException';
  }
}

const getVerseRange = (startVerse, endVerse, content) => {
  const divStart = '<div class=';
  const divMainStart = `<div class="main">`;
  const divMainEnd = '</div>';
  let startIndex = 0;
  let endIndex = content.length - 1;

  if (startVerse !== 1) {
    const startVerseVIdx = content.indexOf(`"V${startVerse}"`);
    const startVerseDivTagIdx = content.lastIndexOf(divStart, startVerseVIdx);
    startIndex = startVerseDivTagIdx;
  }

  const endVerseIndex = content.indexOf(`"V${endVerse + 1}"`);

  if (endVerseIndex !== -1) {
    const endVerseDivTagIdx = content.lastIndexOf(divStart, endVerseIndex);
    endIndex = endVerseDivTagIdx;
  }

  let rangedContent = content.substring(startIndex, endIndex);

  if (startIndex !== 0) {
    rangedContent = divMainStart + rangedContent;
  }

  if (endIndex !== content.length - 1) {
    rangedContent = rangedContent + divMainEnd;
  }

  return rangedContent;
};

export {ReadingParserException};
