import React from 'react';
import {Modal, View, Pressable, StyleSheet, Text} from 'react-native';
import {UIPressable} from './UIPressable';
import Icon from './Icon';
import {useTheme} from '../../theme/ThemeContext';

const UIModal = ({visible, onClose, children, modalTitle, titleStyle}) => {
  const {theme} = useTheme();
  const styles = getStyles(theme);

  return (
    <Modal transparent={true} visible={visible} onRequestClose={onClose}>
      <View style={styles.modalContainer}>
        <Pressable style={styles.modalBackdrop} onPress={onClose}></Pressable>
        <View style={styles.modalContent}>
          <UIPressable onPress={onClose} style={styles.modalCloseBtn}>
            <Icon name="close-circle" size={24} color={theme.colors.icon} />
          </UIPressable>
          <Text style={[styles.modalTitle, titleStyle]}>{modalTitle}</Text>
          {children}
        </View>
      </View>
    </Modal>
  );
};

const getStyles = theme => {
  return StyleSheet.create({
    modalContainer: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },

    modalContent: {
      backgroundColor: theme.colors.navBackground,
      padding: 25,
      borderRadius: 10,
      width: '90%',
      maxWidth: 400,
    },

    modalBackdrop: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },

    modalCloseBtn: {
      position: 'absolute',
      right: 10,
      top: 10,
      padding: 5,
      zIndex: 1,
    },

    modalTitle: {
      color: theme.colors.text,
      fontSize: 18,
      fontFamily: theme.fontFamily,
      paddingBottom: 20,
    },
  });
};
export default UIModal;
