import {delay} from '../promise';

export const unRegisterEventListeners = () => {
  const isMobileBrowser =
    /iPhone|iPad|iPod|Android|SAMSUNG|Samsung|SamsungBrowser|SGH-[I|N|T]|GT-[I|N]|SM-[A|N|P|T|Z]|SHV-E|SCH-[I|J|R|S]|SPH-L/i.test(
      navigator.userAgent,
    );
  const isIos = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  const touchEvent = 'pointerup';

  const mainView = document.getElementsByClassName('reading');
  if (mainView && mainView[0]) {
    if (isMobileBrowser && !isIos) {
      mainView[0].removeEventListener('contextmenu', contextMenuHandler);
    } else if (!isMobileBrowser || isIos) {
      mainView[0].removeEventListener(touchEvent, triggerFetchSelection);
    }
  }
};

export const registerEventListeners = interfaceExecuteCall => {
  window.executeCallbackFunction = interfaceExecuteCall;
  for (
    var e = document.querySelectorAll('.footnote'), t = 0;
    t < e.length;
    t++
  ) {
    e[t].addEventListener('click', function (e) {
      var t;
      e.currentTarget && (t = e.currentTarget.getAttribute('id')),
        (t =
          t ||
          e.target.getAttribute('id') ||
          e.target.parentNode.getAttribute('id')),
        interfaceExecuteCall('activateFootnote', t);
    });
  }

  for (
    var n = document.querySelectorAll('.front-arrow'), t = 0;
    t < n.length;
    t++
  ) {
    n[t].addEventListener('click', function () {
      interfaceExecuteCall('nextChapter');
    });
  }

  // pointerup works on desktop
  const isMobileBrowser =
    /iPhone|iPad|iPod|Android|SAMSUNG|Samsung|SamsungBrowser|SGH-[I|N|T]|GT-[I|N]|SM-[A|N|P|T|Z]|SHV-E|SCH-[I|J|R|S]|SPH-L/i.test(
      navigator.userAgent,
    );
  const isIos = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  const touchEvent = 'pointerup';

  const mainView = document.getElementsByClassName('reading');
  if (mainView && mainView[0]) {
    // Android
    if (isMobileBrowser && !isIos) {
      mainView[0].addEventListener('contextmenu', contextMenuHandler);
    } else if (!isMobileBrowser || isIos) {
      // Desktop
      mainView[0].addEventListener(touchEvent, triggerFetchSelection);
    }
  }
};

const isSelectionBackwards = () => {
  let backwards = false;
  if (window.getSelection) {
    let sel = window.getSelection();
    if (!sel.isCollapsed) {
      let range = document.createRange();
      range.setStart(sel.anchorNode, sel.anchorOffset);
      range.setEnd(sel.focusNode, sel.focusOffset);
      backwards = range.collapsed;
      range.detach();
    }
  }
  return backwards;
};

const cutVerseNumber = idString => {
  const verseNumberString = idString.substring(1, idString.length);
  try {
    const number = parseInt(verseNumberString) - 1;
    return 'V' + number;
  } catch (e) {
    console.log(e);
  }
  return null;
};

const triggerFetchSelection = _ => {
  const selection = document.getSelection();
  const isBackwards = isSelectionBackwards();
  if (selection) {
    if (selection.rangeCount) {
      let anchor = null;
      let endAnchor = null;
      if (isBackwards) {
        anchor = selection.focusNode;
        endAnchor = selection.anchorNode;
      } else {
        anchor = selection.anchorNode;
        endAnchor = selection.focusNode;
      }

      const verseElements = document.getElementsByClassName('verse');
      let lastVerse = null;
      let firstVerse = null;
      let setLast = false;
      for (const verseEl of verseElements) {
        if (
          verseEl.compareDocumentPosition(anchor) &
          Node.DOCUMENT_POSITION_PRECEDING
        ) {
          if (!lastVerse) {
            lastVerse = verseEl.id;
          }
        } else if (
          verseEl.compareDocumentPosition(endAnchor) &
          Node.DOCUMENT_POSITION_FOLLOWING
        ) {
          firstVerse = verseEl.id;
        }

        // when last verse is short word
        if (
          !setLast &&
          verseEl.compareDocumentPosition(endAnchor) &
            Node.DOCUMENT_POSITION_PRECEDING
        ) {
          const endVerseId = cutVerseNumber(verseEl.id);
          lastVerse = endVerseId;
          setLast = true;
        }

        // Check is same verse
        if (
          verseEl.compareDocumentPosition(endAnchor) &
          Node.DOCUMENT_POSITION_PRECEDING
        ) {
          const endVerseId = cutVerseNumber(verseEl.id);
          if (endVerseId === firstVerse) {
            lastVerse = firstVerse;
            break;
          }
        }
      }

      if (!firstVerse) {
        firstVerse = 'V1';
      }
      if (!lastVerse) {
        lastVerse = firstVerse;
      }
      if (lastVerse === 'V0') {
        lastVerse = firstVerse;
      }
      const verseRange = firstVerse + ' - ' + lastVerse;
      window.executeCallbackFunction(
        'selection',
        selection.toString(),
        verseRange,
      );
    }
  }
};

const contextMenuHandler = e => {
  e.preventDefault();
  e.stopPropagation();
  if (e?.pointerId === 1) {
    delay(100).then(() => {
      triggerFetchSelection();
      return false;
    });
  } else {
    return false;
  }
};

export const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    var context = this,
      args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
};
