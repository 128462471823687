import React, {createContext, useContext, useState} from 'react';

const PlayerContext = createContext();

export const PlayerProvider = ({children}) => {
  const [isPlayerOpen, setIsPlayerOpen] = useState(false);
  const [trackAudioPosition, setTrackAudioPosition] = useState(true);
  const [audioPosition, setAudioPosition] = useState({});
  const [forceCloseAudioPlayer, setForceCloseAudioPlayer] = useState(false);
  const [playPlayerChapter, setPlayPlayerChapter] = useState(null);
  const [isPlayerReady, setIsPlayerReady] = useState(false);

  const openPlayer = () => {
    setIsPlayerOpen(true);
    if (forceCloseAudioPlayer) {
      setForceCloseAudioPlayer(false);
    }
  };

  const closePlayer = () => {
    setIsPlayerOpen(false);
    if (forceCloseAudioPlayer) {
      setForceCloseAudioPlayer(true);
    }
  };

  return (
    <PlayerContext.Provider
      value={{
        isPlayerOpen,
        openPlayer,
        closePlayer,
        trackAudioPosition,
        setTrackAudioPosition,
        audioPosition,
        setAudioPosition,
        forceCloseAudioPlayer,
        setForceCloseAudioPlayer,
        playPlayerChapter,
        setPlayPlayerChapter,
        isPlayerReady,
        setIsPlayerReady
      }}>
      {children}
    </PlayerContext.Provider>
  );
};

export const usePlayer = () => {
  return useContext(PlayerContext);
};
