import React from 'react';
import {View, Text, StyleSheet} from 'react-native';
import UIModal from '../ui-components/UIModal';
import globalStyle from '../../theme/globalStyle';
import {useTheme} from '../../theme/ThemeContext';
import UIButton from '../ui-components/UIButton';

const DeleteConfirmationModal = ({
  visible,
  onConfirm,
  onCancel,
  quoteText,
  modalContent,
  modalTitle,
}) => {
  const {theme} = useTheme();
  const global = globalStyle(theme);
  const styles = getStyles(theme);

  return (
    <UIModal
      visible={visible}
      onClose={onCancel}
      modalTitle={modalTitle}
      titleStyle={styles.titleStyle}>
      <Text style={[global.textMain, styles.text, {marginBottom: 10}]}>
        {modalContent}:
      </Text>
      <Text style={[global.textBold, styles.text]}>{quoteText}</Text>

      <View style={global.modalFooter}>
        <UIButton
          title="Odustani"
          type="outline"
          style={styles.btn}
          onPress={onCancel}
        />
        <UIButton title="Obriši" style={styles.btn} onPress={onConfirm} />
      </View>
    </UIModal>
  );
};

const getStyles = theme =>
  StyleSheet.create({
    titleStyle: {
      color: theme.colors.primary,
    },

    text: {fontSize: 16},

    content: {
      marginTop: 15,
      marginBottom: 20,
    },

    btnWrapper: {
      flexDirection: 'row',
      justifyContent: 'center',
    },

    btn: {
      marginHorizontal: 5,
      minWidth: 110,
    },
  });

export default DeleteConfirmationModal;
