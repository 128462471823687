import {useContext} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {book_title, positionInBook} from '../../constants/stringsAndFields';
import DataContext from '../../context/dataContext';
import globalStyle from '../../theme/globalStyle';
import {useTheme} from '../../theme/ThemeContext';
import UIButton from '../ui-components/UIButton';
import UIModal from '../ui-components/UIModal';

const ActionPickerModal = ({
  modalVisible,
  setModalVisible,
  setSelectedText,
  selectedText,
  selectedVerses = '',
  handleSelectionEvent,
  chapter = null,
  currentIndex = 0,
}) => {
  const {chapters} = useContext(DataContext);
  const {theme} = useTheme();
  const global = globalStyle(theme);
  const styles = getStyles(theme);

  const closeModal = () => {
    setModalVisible(false);
  };

  const bookTitle = chapters?.[currentIndex]?.[book_title] ?? '';
  const chapterNumber = chapters?.[currentIndex]?.[positionInBook] ?? '';
  const verseRange =
    selectedVerses?.replaceAll('V', '')?.replaceAll(' ', '') ?? '';
  const selectedVerseText = `${bookTitle} ${chapterNumber}:${verseRange}`;

  return (
    <UIModal
      visible={modalVisible}
      onClose={closeModal}
      modalTitle={'Odabrano:'}
      titleStyle={styles.titleStyle}>
      <Text style={global.textTitle}>{selectedVerseText}</Text>

      <View style={[global.modalFooter, styles.modalFooter]}>
        <UIButton
          title="Dodaj u omiljene"
          type="outline"
          style={styles.btn}
          onPress={_ => {
            const text = selectedText;
            handleSelectionEvent({
              key: 'favorite',
              selectedText: text,
              verses: selectedVerses,
            });
            closeModal();
          }}
        />
        <UIButton
          title="Dodaj bilješku"
          type="outline"
          style={styles.btn}
          onPress={_ => {
            handleSelectionEvent({
              key: 'note',
              selectedText: null,
              verses: selectedVerses,
            });
            setModalVisible(false);
          }}
        />
        <UIButton
          title="Kopiraj"
          type="outline"
          style={styles.btn}
          onPress={_ => {
            const text = selectedText;
            handleSelectionEvent({
              key: 'copy',
              selectedText: text,
              verses: selectedVerses,
            });
            closeModal();
          }}
        />
        <UIButton
          title="Odustani"
          style={[styles.btn, {marginTop: 10}]}
          onPress={_ => {
            closeModal();
          }}
        />
      </View>
    </UIModal>
  );
};

const getStyles = theme =>
  StyleSheet.create({
    titleStyle: {
      paddingBottom: 10,
    },

    modalFooter: {
      flexDirection: 'column',
      alignItems: 'center',
    },

    btn: {
      marginBottom: 10,
      minWidth: 180,
    },
  });

export default ActionPickerModal;
