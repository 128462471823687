import React from 'react';
import {View, StyleSheet, useWindowDimensions} from 'react-native';
import {UIPressable} from './UIPressable';
import Icon from './Icon';
import {useTheme} from '../../theme/ThemeContext';
import {themeMapper} from '../../theme/themes';

const UIBottomSheet = ({visible, onClose, children, style}) => {
  const {theme} = useTheme();
  const styles = getStyles(theme);
  const width = useWindowDimensions().width;
  const isMobile = width < 480;
  const isDesktop = width > 1200;

  return (
    <div className={themeMapper(theme.name)}>
      <View
        visible={visible}
        onClose={!visible}
        style={[
          styles.modal,
          visible && styles.modalVisible,
          isMobile && styles.modalMobile,
          isMobile && {maxWidth: width - 30},
          {bottom: isDesktop ? 20 : 70},
          style,
        ]}>
        <UIPressable onPress={onClose} style={styles.modalCloseBtn}>
          <Icon name="close-circle" size={24} color={theme.colors.icon}></Icon>
        </UIPressable>
        {children}
      </View>
    </div>
  );
};

const getStyles = theme => {
  return StyleSheet.create({
    modal: {
      position: 'fixed',
      right: 30,
      width: '100%',
      maxWidth: 380,
      backgroundColor: theme.colors.navBackground,
      borderWidth: 1,
      borderColor: theme.colors.border,
      paddingHorizontal: 15,
      paddingTop: 15,
      paddingBottom: 10,
      borderRadius: 10,
      shadowColor: theme.colors.shadow,
      shadowOpacity: 0.9,
      shadowOffset: {width: 0, height: 0},
      shadowRadius: 10,
      elevation: 5,
      display: 'none',
      color: theme.colors.text,
    },

    modalMobile: {
      left: 15,
      right: 15,
    },

    modalVisible: {
      display: 'flex',
    },

    modalContainer: {
      flex: 1,
      justifyContent: 'flex-end',
      alignItems: 'center',
    },

    modalCloseBtn: {
      position: 'absolute',
      right: 5,
      top: 5,
      padding: 5,
      zIndex: 1,
    },
  });
};
export default UIBottomSheet;
