import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import UIBottomSheet from '../ui-components/UIBottomSheet';
import {StyleSheet, Text, View} from 'react-native';
import {useTheme} from '../../theme/ThemeContext';
import globalStyle from '../../theme/globalStyle';
import {
  deleteLastListening,
  getLastListening,
  getLastReading,
  saveLastListening,
} from '../../utils/storage';
import {useContext, useEffect, useRef, useState} from 'react';
import {
  additionalBook,
  AUDIO_PARENT_LINK,
  audioBookName,
  book_title,
  chapterId,
  positionInBook,
  track,
} from '../../constants/stringsAndFields';
import DataContext from '../../context/dataContext';
import {usePlayer} from '../audio-player/PlayerContext';
import Icon from '../ui-components/Icon';

export const AudioBottomSheet = ({modalVisible, setModalVisible, style}) => {
  const {getChapter, getBookByName, getNextChapter, getPreviousChapter} =
    useContext(DataContext);
  const {
    trackAudioPosition,
    setTrackAudioPosition,
    setAudioPosition,
    playPlayerChapter,
  } = usePlayer();

  const {theme} = useTheme();
  const global = globalStyle(theme);
  const styles = getStyles(theme);
  const [chapterAudioLink, setChapterAudioLink] = useState(null);
  const [listeningChapter, setListeningChapter] = useState(null);
  const [isLooping, setIsLooping] = useState(false);

  const audioPlayerRef = useRef(null);

  const toggleLoop = () => {
    setIsLooping(prev => !prev);
    if (audioPlayerRef.current?.audio?.current) {
      audioPlayerRef.current.audio.current.loop = !isLooping;
    }
  };

  const handleStopTrackingAudioPosition = () => {
    setTrackAudioPosition(!trackAudioPosition);
  };

  const closeModal = () => {
    if (audioPlayerRef.current?.audio?.current) {
      audioPlayerRef.current.audio.current.pause();
    }
    setModalVisible(!modalVisible);
  };

  // -1 previous, 0 current, 1 next
  const resolveAndPlayTrack = async (
    index = 0,
    readingPlanChapterId = null,
  ) => {
    try {
      let chapter = null;
      let activeChapterId = null;
      if (readingPlanChapterId) {
        const lastChapter = await getChapter(readingPlanChapterId);
        chapter = !lastChapter[additionalBook] ? lastChapter : null;
      } else {
        activeChapterId = await getLastReading();
        const lastListening = await getLastListening();
        if (lastListening?.[track]?.[chapterId]) {
          activeChapterId = lastListening[track][chapterId];
        }
        if (activeChapterId) {
          chapter = await getChapter(activeChapterId);
        }
      }
      if (index === -1 && activeChapterId) {
        chapter = await getPreviousChapter(activeChapterId);
      } else if (index === 1 && activeChapterId) {
        chapter = await getNextChapter(activeChapterId);
      }
      if (chapter) {
        const book = await getBookByName(chapter[book_title]);
        if (book && chapter[positionInBook] >= 0) {
          const chapterLink = `${AUDIO_PARENT_LINK}/${book[audioBookName]}/${chapter[positionInBook]}.m4a`;
          setChapterAudioLink(chapterLink);
          setListeningChapter(chapter);
          await saveLastListening(chapter, 0);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onListenCallback = async _ => {
    const currentListeningTime =
      audioPlayerRef?.current?.audio?.current?.currentTime;
    if (currentListeningTime) {
      let activeChapterId = await getLastReading();
      const lastListening = await getLastListening();
      if (lastListening?.[track]?.[chapterId]) {
        activeChapterId = lastListening[track]?.[chapterId];
      }
      const chapter = await getChapter(activeChapterId);
      if (chapter) {
        setAudioPosition({
          position: currentListeningTime,
          chapterId: chapter[chapterId],
        });
      }
    }
  };

  const onClickPrevious = async _ => {
    await resolveAndPlayTrack(-1, null);
  };

  const onClickNext = async _ => {
    await resolveAndPlayTrack(1, null);
  };

  useEffect(() => {
    if (modalVisible) {
      resolveAndPlayTrack(0, playPlayerChapter).catch(console.error);
      setTrackAudioPosition(true);
    } else {
      deleteLastListening().catch(console.error);
      setTrackAudioPosition(false);
    }
  }, [modalVisible, playPlayerChapter]);

  return (
    <UIBottomSheet visible={modalVisible} onClose={closeModal} style={style}>
      <Text style={global.textBold}>
        {listeningChapter?.[book_title]} {listeningChapter?.[positionInBook]}
      </Text>
      <View style={styles.controlsWrapper}>
        <AudioPlayer
          src={chapterAudioLink}
          onListen={onListenCallback}
          ref={audioPlayerRef}
          onClickPrevious={onClickPrevious}
          onClickNext={onClickNext}
          showSkipControls={true}
          showJumpControls={false}
          loop={true}
          customIcons={{
            play: <Icon name="control-play" style={styles.controlbBtn} />,
            pause: <Icon name="control-pause" style={styles.controlbBtn} />,
            previous: <Icon name="arrow-left" style={styles.controlbBtn} />,
            next: <Icon name="arrow-right" style={styles.controlbBtn} />,
            volume: <Icon name="volume-1" style={styles.controlbBtn} />,
            volumeMute: <Icon name="volume-off" style={styles.controlbBtn} />,
          }}
          customAdditionalControls={[
            <Icon
              name={isLooping ? 'repeat' : 'no-repeat'}
              style={[
                styles.controlbBtn,
                isLooping && styles.controlbBtnActive,
              ]}
              onPress={toggleLoop}
            />,
            <Icon
              name={trackAudioPosition ? 'autoscroll-on' : 'autoscroll-off'}
              style={[
                styles.controlbBtn,
                trackAudioPosition && styles.controlbBtnActive,
              ]}
              onPress={handleStopTrackingAudioPosition}
            />,
          ]}
        />
      </View>
    </UIBottomSheet>
  );
};

const getStyles = theme => {
  return StyleSheet.create({
    controlsWrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 10,
    },

    controlbBtn: {
      fontSize: 20,
      width: 40,
      height: 40,
      display: 'flex',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.colors.text,
      fontFamily: theme.fontFamily,
    },

    controlbBtnActive: {
      color: theme.colors.primary,
    },
  });
};
