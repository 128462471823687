import {getHeaderTitle} from '@react-navigation/elements';
import {useIsFocused} from '@react-navigation/native';
import {useContext, useEffect, useState} from 'react';
import {useTheme} from '../theme/ThemeContext';
import ReadingPlanContentList from '../components/reading-plan/ReadingPlanContentList';
import ReadingPlanEntryList from '../components/reading-plan/ReadingPlanEntryList';
import UILoadingView from '../components/ui-components/UILoadingView';
import {readingEntryId, readingId, readingPlanId} from '../constants/stringsAndFields';
import DataContext from '../context/dataContext';
import {getIndexByReadingEntryId} from '../utils/arrays';
import {getCurrentDayOfYear} from '../utils/dateTime';
import {UIPressable} from '../components/ui-components/UIPressable';
import {Text} from 'react-native';
import UINavHeader from '../components/ui-components/UINavHeader';
import ReadingPlanChooser from '../components/reading-plan/ReadingPlanChooser';

const ReadingPlanScreen = ({navigation, route}) => {
  const {getDailyReading, getDailyReadings} = useContext(DataContext);
  const [dailyReadings, setDailyReadings] = useState(null);
  const [selectedEntryItem, setSelectedEntryItem] = useState(null);
  const [readingPlanContentItems, setReadingPlanContentItems] = useState(null);
  const [initialSetupCompleted, setInitialSetupCompleted] = useState(false);
  const [scrollIndex, setScrollIndex] = useState(getCurrentDayOfYear() - 1);
  const isFocused = useIsFocused();
  const {theme} = useTheme();
  const [readingPlanChooserVisible, setReadingPlanChooserVisible] =
    useState(false);
  const [readingPlanID, setReadingPlanID] = useState('2');

  const openReadingPlanChooser = () => {
    setReadingPlanChooserVisible(true);
  };

  useEffect(() => {
    if (isFocused && !initialSetupCompleted) {
      initialScreenSetup().catch(console.error);
    } else if (isFocused && initialSetupCompleted) {
      refreshDailyReadings(readingPlanID).catch(console.error);
    }
  }, [isFocused]);

  useEffect(() => {
    const readingPlanTitle =
      readingPlanID === '1' ? 'Standardni Plan' : 'Tematski Plan';

    const headerRightButton = (
      <UIPressable onPress={openReadingPlanChooser}>
        <Text style={{color: theme.colors.primary, paddingRight: 15}}>
          {readingPlanTitle}
        </Text>
      </UIPressable>
    );

    navigation.setOptions({
      header: ({navigation, route, options}) => {
        const title = getHeaderTitle(options, route.name);
        return <UINavHeader title={title} headerRight={headerRightButton} />;
      },
    });
  }, [navigation, readingPlanID]);

  const initialScreenSetup = async () => {
    await initiallySetDailyReadings();
    setInitialSetupCompleted(true);
  };

  const refreshDailyReadings = async plan => {
    const readings = await getDailyReadings(plan);
    if (readings) {
      const refreshedDailyReadings = [...readings];
      setDailyReadings(refreshedDailyReadings);
      const readingIndex = getIndexByReadingEntryId(
        refreshedDailyReadings,
        selectedEntryItem,
      );
      setScrollIndex(readingIndex);
      await onReadingPlanEntryItemPress(refreshedDailyReadings[readingIndex]);
    }
  };

  const onReadingPlanEntryItemPress = async selectedItem => {
    const daily = await getDailyReading(
      selectedItem[readingPlanId],
      selectedItem[readingEntryId],
    );
    if (daily) {
      setReadingPlanContentItems(daily);
      setSelectedEntryItem(selectedItem);
    }
  };

  const onReadingPlanContentItemPress = async selectedItem => {
    navigation.navigate('Reading', {
      screen: 'PlanDetails',
      params: {readingId: selectedItem[readingId]},
    });
  };

  const initiallySetDailyReadings = async () => {
    const readings = await getDailyReadings(readingPlanID);
    if (readings) {
      setDailyReadings(readings);
      setSelectedEntryItem(readings[getCurrentDayOfYear() - 1]);
    }
    const daily = await getDailyReading(readingPlanID, getCurrentDayOfYear());
    if (daily) {
      setReadingPlanContentItems(daily);
    }
  };

  const closeReadingPlanChooser = selectedPlanID => {
    setReadingPlanChooserVisible(false);
    if (selectedPlanID !== readingPlanID) {
      setReadingPlanID(selectedPlanID);
      refreshDailyReadings(selectedPlanID).catch(console.error);
    }
  };

  if (!initialSetupCompleted) {
    return <UILoadingView textData={''} />;
  }

  return (
    <>
      {readingPlanChooserVisible && (
        <ReadingPlanChooser
          visible={readingPlanChooserVisible}
          onClose={closeReadingPlanChooser}
          currentReadingPlanID={readingPlanID}
        />
      )}
      <ReadingPlanEntryList
        readingPlanEntryItems={dailyReadings}
        initialScrollIndex={scrollIndex}
        onItemPress={onReadingPlanEntryItemPress}
        selectedItem={selectedEntryItem}
      />
      <ReadingPlanContentList
        readingContentItems={readingPlanContentItems}
        onItemPress={onReadingPlanContentItemPress}
      />
    </>
  );
};

export default ReadingPlanScreen;
