export const baseStyle = `
    <style> 
        .htmlClass {
            font-size: 10px;
        }

        .htmlClass,
        .bodyClass {
            margin: 0;
            padding: 0;
            background-color: 'transparent';
        }

        .reading {
            font-family: 'Noto Serif';
            font-size: 1.8rem;
            line-height: 1.5;
            padding: 2.5rem 2rem;
        }

        .reading p,
        .reading div.p,
        .reading div.pi,
        .reading div.mi,
        .reading div.ipi,
        .reading div.iot,
        .reading div.ms,
        .reading div.li {
            margin: 2rem 0;
            text-align: left;
        }

        .reading div.m {
            margin: 1.5rem 0;
            text-align: left;
        }

        .reading span.it {
            font-style: italic;
        }

        .reading div.io {
            margin: 1rem 0 1rem 4rem;
            text-align: left;
            display: list-item;
            list-style: disc;
        }

        .reading h3 {
            font-size: 3.2rem;
            line-height: 1.3;
            margin: 2.5rem 1.5rem;
        }

        .reading .mt2,
        .reading .mt {
            font-size: 3.2rem;
            text-transform: uppercase;
            text-align: center;
            font-weight: bold;
            padding-top: 1rem;
            padding-bottom: 1rem;
        }

        .reading .chapterlabel {
            display: none;
        }

        .reading div.s,
        .reading div.m_s,
        .reading h1 {
            font-size: 3rem;
            text-align: center;
            font-weight: bold;
            padding-top: 1.5rem;
            padding-bottom: 1rem;
        }

        .reading div.m_s {
            margin-bottom: 2rem;
        }

        .reading div.s2 {
            font-size: 2.2rem;
            text-align: center;
            font-weight: bold;
            padding-top: 1rem;
        }

        .reading div.q,
        .reading div.q2,
        .reading q,
        .reading blockquote {
            font-style: italic;
            width: 85%;
            margin: 1rem auto;
        }

        .reading div.r {
            display: none;
        }

        .reading div.b {
            height: 1rem;
        }

        .verse,
        .verse-num {
            position: relative;
            top: -0.5rem;
            font-size: 1.2rem;
            color: #a1a1a1;
        }

        .reading div.q .verse,
        .reading div.q2 .verse {
            top: -7px;
        }

        .reading sup {
            font-size: 1.4rem;
            padding: 2px;
        }

        .footnote,
        .bodyClass p .verse .wordwrap {
            white-space: nowrap
        }
        
        .reference, sup {
            color: #9a9a9a;
        }

        .popup {
            display: none;
        }

        .next-section {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-bottom: 30px;
            margin-top: 30px;
        }
   
        .next-section h4 {
            font-family: 'Open Sans';
            font-size: 1.4rem;
            font-weight: 400;
            padding: 0;
        }

        .front-arrow {
            height: 20px;
            width: 20px;
            margin-right: 10px; 
            margin-left: 10px;
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: contain;
            transform: rotate(180deg);
        }

        table {
            margin: 1rem 0;
        }

        .m_measures1 {
            margin: 0 0.9rem;
        }

        table {
            width: 100%;
            border-collapse: collapse;
            border-width: 0 !important;
            border-color: transparent;
            font-size: 1.4rem;
            text-align: left;
        }

        table tr td {
            padding: 0.5rem 0;
        }

        table tr {
            border-bottom-width: 1px;
            border-bottom-style: solid;
        }

        .m_measures-title {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        .m_measures-title p {
            font-weight: bold;
            font-size: 1.5rem;
            margin: 0;
        }

        .m_measures-title p:last-child {
            text-align: right;
        }

        .m_measures-subtitle p {
            font-weight: bold;
            font-style: italic;
            text-align: right;
            font-size: 1.4rem;
            margin: 2rem 0 0.5rem 0;
        }

        .m_measures-table tr.subtitle {
            border-bottom-width: 0;
            font-weight: bold;
            font-style: italic;
        }

        .m_measures-table tr td:first-child {
            text-align: left;
        }

        .m_measures-table tr td:last-child {
            text-align: right;
        }

        p.m_measures-info {
            font-size: 1.4rem;
            margin-left: 0;
            margin-right: 0;    
        }
    </style>
`;

export const themeStyle = `
    <style>
        /* Light */
        .theme-light.reading {
            background-color: #fcfcfc;
            color: #222;
        }
        .theme-light .front-arrow {
            background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAAAXNSR0IArs4c6QAAAuBJREFUeAHt271TE0EYx/EzY6NS2dCqrTM2MDQKhMbOztp/RfhTpKaUl47J2DE0FLZaojMGdJDYZd0n3GYuL3MJd0n2yT7fm2FIILnb/fzy7CV7myxjQwABBBBAAAEEEEAAAQQQQAABBBBAAAEEEEAAAQQQQAABBBBAAAEEEEAAAQSWWKD1YmNXfjR14YGmxsyzLT34bvdj7xiNxt72t7PdeR5v2n2bCGAAP8goCSH5AMbiKwoh6QBK8ZWEkGwAX55vfOi67qfgXPo74nDUKG3YEv/z0dPVA9/806m64E/OvWqZ6sGzfVCyFSBM52vvHt+2Lz/7mztTsUWohKQDCCF02peHLsuaGkNIPgDtIZgIoB/C1Y8j59y2pkowE4CgX7x6++TPzfWhphBMBVAIQSphS0MlmAtAWwgmAwgh/L65Ps6c24xZCWYDEPSvL5srvzq3RzFDMB1ACKHd+XvsXPYmRiWYDyB2CASQv+xlOPKVcOIr4fUiK4EACtoxQrh3AK1n635aha0vUHMCL9np6D7QvG/UnMomgFkEVCMEAphFALKPiiEQwKwCqLgfAqgIN/K0iidjAhiRrPCHivhyJAKo4D3wlBr4sp97fw4YOHhid5big1hi5v3uxMCXgzMEeYQcX2ZEFzoPJAGYH4IK+FGmox9KClY3wb+7IBPnWoC4mx2CZIVE7KthZgPIl6dEvRQZRh1z54CAr2VZiqkhqLAwS8WaIFNDkKyU1rYqTgIw8S5I8PMV0qrWhUoAyZ8DCvhN6fDErebczsT9Dz0g6QoQ/PwLGs2hfo+/u2B8aUSyFVDA3xmvPfTXCPjSgmTfBf27+vne9081ftIBbH4/28/8q1o6WbpFeuWHNiU7BIUOln5XODK+tDH5AKSTY0NQgG8mgJEQlOBLu0xtUgmxvpBtCprOIoAAAggggAACCCCAAAIIIIAAAggggAACCCCAAAIIIIAAAggggAACCCBgXOA/ICZ1uWW5droAAAAASUVORK5CYII=");
        }
        .theme-light .verse,
        .theme-light .verse-num,
        .theme-light .next-section h4 {
            color: #a1a1a1;
        }

        .theme-light table tr {
            border-bottom-color: #c8c8c8;
        }

        .theme-light table tr td {
            color: #222;
        }

        /* Dark */
        .theme-dark.reading {
            background-color: #343434;
            color: #fff;
        }
        .theme-dark .front-arrow {
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAAAXNSR0IArs4c6QAAAiFJREFUeAHt22tOAyEUBlDrT3WNbsVxY9bXvurFgInGVqCM2HomIePjAtPzQdU6vbhwECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBA4IQFdrvdktoJP4TTvfSMH6f3Qwi/GWWQp5X/9RDCb4QQ6t/hlzCEsGYIP+ALYWX82yJccbYTRocR6FfRHirwS4kQhDBa4A+Ml3fCtizzirOdMDo3IYwW7Rgvh/BYsQNKiZ3Q4XywS8heRxPCQaWVv5lDeCrLvOK8rHxJ/294IfyBzHMIzxU7oJTYCaNzC9mbaEIYDdsyXg7hpSzzirOd0AJcUyuEGqWVa3IIrxU7oJQsK1/S/xt+RgibVuYUf2ufM6+/32w2S+9jvOztqN+HwF2syeXjs8YPBNAItqe8OwQB7BHt+HJXCALokB7ZRQDjNLt+GAtgTABd+GlqARwfQDf+8VOf2Qjx62R6cc5fwzNyhT9DPc+Z8b0iOiMD+DPUP698/5CZkUGs/HSHBPyJ+O6MgD9DYOKc+WnHjVkzMgj8dLs6/In42wig9lhmXOdZzhniaeVva+WjDv6olRCY3iUzCrN1HPitYoPrIwBv0hts2jxcej6P9tOxNA+sQ71A6B8KAX49ZX/lnhDg95O29/wSAvx2wuN75BDgH09pBAIECBAgQIAAAQIECBAgQIAAAQIECBAgQIAAAQIECBAgQIAAAQIEDgi8AaSt0eLdDZLuAAAAAElFTkSuQmCC')
        }
        .theme-dark sup {
            color: #d7d7d7;
        }
        .theme-dark .verse,
        .theme-dark .verse-num,
        .theme-dark .next-section h4 {
            color: #a1a1a1;
        }

        .theme-dark table tr {
            border-bottom-color: #d7d7d7;
        }

        .theme-dark table tr td {
            color: #fff;
        }
        
        /* Sepia */
        .theme-sepia.reading {
            background-color: #f3e6d8;
            color: #403126;
        }
        .theme-sepia .front-arrow {
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAAAXNSR0IArs4c6QAAAzRJREFUeAHt3D2PEkEcBvBbtVI/AtGzgAYqCBS+rg0dnbVfxfMb+Dnc1rfSms4WQ0JDAhUUQEBI8P94TLK5nLu4bzPO/5nkDnZhmZnfM7PLZeAuLlgoQAEKUIACFKAABShAAQpQgAIUoAAFKEABClCAAhSgAAUoQAEKUIACFKAABShAAQr8xwK9Xu8KPy514a5LjSmzLYA/Ho/vpI6wVqsF0+n0e5n1nfvaKgKI4RsXZ0LwPoBb8J0KwesAEvCdCcHbAAT/rZzzPxjphFurpyNvA2i32z/X6/VTgX+SgG8eshaCtwGMRqO9hBC5HoK3AWBomxBWq9Uz2bzEvpRS+UzwOgBgI4ROpxO5GoL3AdwI4blsX2JfSqlsJqgIANiYCa1WK9put06djtQEgBDG4/GfEHa7HWbCY+xLKaXPBFUBABshNJvNyJUQ1AVwI4QXsm11JqgMwIRQr9ejw+FgNQS1ASCEyWTy6xTCS9l8hH0ppfBrguoAgG07BPUBxEPY7/evZLvSmcAAkIAUzIRGo/Gx6hCC6+rP/93tdo/nP9v/ZwZB8H44HF5l7emdrAfyuGsBrDNj4SerBwPIKhc7Lk8IDCAGmedu1hAYQB71Ao5lAAUg4iWyXowZQAEBZMVH1QwgZwB58FH1P/8dkLO9Th8ehuFDWcT/Jo3Eok1qyYuPChjAidkGPqrmKUgQgL/ZbL7K3cpGPvBR1M8Agy/v47FMmVqKOO3EK1E9A074X2zhIwi1AfT7/Qdy2gE+VsRSS9Ej31SoMgDgL5dL6/gIQd01IIaPZcjUUtbINxXfM3c03AJ/sVh8lr46gQ9zNaegwWBw/4SPZcfUUvbINw1QEQDwZ7MZRr5T+AjB+2tADD9Eh9NKVSPftMPrGQD8+Xz+STobmg4n3VaNj7Z4OwMMvrzPf52Ebh6zgY+6vZ0BMvLfuI6PALz9XJB8E/4HvhEvfQzR0b8VWyPftMfbANBB/DuCpBBs46ONXgeQFIIL+CoCuC0EV/DRNlUFn2DL8yk2VVjsLAUoQAEKUIACFKAABShAAQpQgAIUoAAFKEABClCAAhSgAAUoQAEKUIACFKAABSiQWeA3xCCboh0VPjwAAAAASUVORK5CYII=')
        }  
        .theme-sepia sup {
            color: #817a7a;
        }
        .theme-sepia .verse,
        .theme-sepia .verse-num,
        .theme-sepia .next-section h4 {
            color: #967f6f;
        }

        .theme-sepia table tr {
            border-bottom-color: #817a7a;
        }

        .theme-sepia table tr td {
            color: #403126;
        }

        /* Font */
        .font-sans.reading {
            font-family: 'Open Sans';
        } 
        .font-serif.reading {
            font-family: 'Noto Serif';
        } 

        .font-normal.htmlClass {
            font-size: 10px;
        }
        .font-small.htmlClass {
            font-size: 8px;
        }
        .font-large.htmlClass {
            font-size: 12px;
        }
    </style>
`;
